import ImageSrc from "../../../assets/retailer/getInTouch.webp";
import "./style.scss";

const GetInTouch = () => {
  return (
    <div className="justR_getInTouch">
      <div className="container">
        <div className="left_section">
          <h2 className="heading">Send Enquiry</h2>
          <p>Required Details</p>
          <div className="powr-contact-form" id="5c9511d3_1621924877"></div>
        </div>
        <div className="right_section">
          <img src={ImageSrc} alt="contact form" />
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
