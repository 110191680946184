import React, {useEffect} from 'react'
import About from './About/About';
import GetInTouch from './GetInTouch/GetInTouch';
import JustRetailerHero from './Hero/JustRetailerHero';
import JustApp from './JustApp/JustApp';
import JustProducts from './JustProducts/JustProducts';
import Presence from './Presence/Presence';
import WhyJust from './whyJust/WhyJust';
import "./style.scss";
import Footer from './footer/Footer';

const JustRetailer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="overflow-hidden just_retailer_site">
      <JustRetailerHero/>
      <About/>
      <WhyJust/>
      <JustApp/>
      <JustProducts/>
      <Presence/>
      <GetInTouch/>
      <Footer/>
    </div>
  )
}

export default JustRetailer;