import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

type TrackEvent = {
  event: string;
  eventPayload: {
    page?: string;
    event_type?: string;
    device?: 'mobile' | 'desktop';
    session_id?: string | null;
  };
};

interface CustomWindow extends Window {
  Moengage: any;
}

declare let window: CustomWindow;

const useMoengage = () => {
  const uuid = uuidv4();
  const sessionId = sessionStorage.getItem('session_id');
  const regexp = /android|iphone|kindle|ipad/i;
  const isMobile = regexp.test(navigator.userAgent);

  useEffect(() => {
    if(sessionId === null){
      sessionStorage.setItem("session_id", uuid);
    }
  }, [uuid])
  

  const trackEvent = ({ event, eventPayload }: TrackEvent) => {
    eventPayload.page = eventPayload.page ? eventPayload.page : 'homepage';
    eventPayload.event_type = eventPayload.event_type ? eventPayload.event_type : 'click';
    eventPayload.session_id = sessionId;
    const device = isMobile ? 'mobile' : 'desktop';
    eventPayload = { ...eventPayload, device,};
    window.Moengage?.track_event(event, eventPayload);
  };

  return { trackEvent };
};

export default useMoengage;
