import "./styles.scss";
import { PicturesData } from "./constants";
import DownloadNow from "../../Common/DownloadNow";

const Pictures = () => {
 
  return (
    <div className="pictutes-container">
      {PicturesData.map((item, index) => (
        <div className="row" key={index}>
          <div className={item.imageCol}>
            <img src={item.img} alt={item.altTag} title={item.altTag} className="pictures-img" />
          </div>
          <div className={item.contentCol}>
            <h2 className="picture-heading">{item.heading}</h2>
            <div className="picture-subtitle">{item.subtitle}</div>
            {item.showDownload && (
              <DownloadNow playStoreLink='https://gojust.store/Get_App2' from='process'/>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
export default Pictures;
