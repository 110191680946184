import React from 'react'
import './style.scss';
import partnerOneImg from '../../../assets/images/JustRetailer/presence/partner10.webp';
import partnerTwoImg from '../../../assets/images/JustRetailer/presence/partner11.webp';
import partnerThreeImg from '../../../assets/images/JustRetailer/presence/partner12.webp';
import partnerFourImg from '../../../assets/images/JustRetailer/presence/partner13.webp';
import partnerFiveImg from '../../../assets/images/JustRetailer/presence/partner15.webp';
import partnerSixImg from '../../../assets/images/JustRetailer/presence/partner16.webp';
import partnerSevenImg from '../../../assets/images/JustRetailer/presence/partner17.webp';
import partnerEightImg from '../../../assets/images/JustRetailer/presence/partner18.webp';
import partnerNineImg from '../../../assets/images/JustRetailer/presence/partner19.webp';
import partnerTenImg from '../../../assets/images/JustRetailer/presence/partner20.webp';
import partnerElevenImg from '../../../assets/images/JustRetailer/presence/partner21.webp';
import partnerTwelveImg from '../../../assets/images/JustRetailer/presence/partner22.webp';
import partnerThirteenImg from '../../../assets/images/JustRetailer/presence/partner14.webp';
import partnerFourteenImg from '../../../assets/images/JustRetailer/presence/partner23.webp';


const Presence = () => {
  return (
    <div className='justR_presence'>
      <div className="container">
        <h2>Just Clientele</h2>
        <p>We are present at leading retail stores and neighbourhood shops across Delhi NCR.</p>
          <div className="partners">
            <div className="partner">
              <img src={partnerOneImg}alt="Swiggy Instamart" title="Swiggy Instamart" />
            </div>
            <div className="partner">
              <img src={partnerTwoImg} alt="Spencers" title="Spencers" />
            </div>
            <div className="partner">
              <img src={partnerThreeImg} alt="Zepto" title="Zepto" />
            </div>
            <div className="partner">
              <img src={partnerFourImg} alt="Le Marche" title="Le Marche" />
            </div>
            <div className="partner">
              <img src={partnerFiveImg} alt="Blink It" title="Blink It" />
            </div>
            <div className="partner">
              <img src={partnerSixImg} alt="Country Delight" title="Country Delight" />
            </div>
            <div className="partner">
              <img src={partnerSevenImg} alt="Otipy" title="Otipy" />
            </div>
            <div className="partner">
              <img src={partnerEightImg} alt="Milk Basket" title="Milk Basket" />
            </div>
            <div className="partner">
              <img src={partnerNineImg} alt="more" title="more" />
            </div>
            <div className="partner">
              <img src={partnerTenImg} alt="Honey" title="Honey" />
            </div>
            <div className="partner-lots">
              <img src={partnerElevenImg} alt="Lots" title="Lots" />
            </div>
            <div className="partner-SecondLast">
              <img src={partnerTwelveImg} alt="Lots" title="Lots" />
            </div>
            <div className="partner-lots">
              <img src={partnerThirteenImg} alt="Nafed Bazaar" title="Nafed Bazaar" />
            </div>
            <div className="partner-last">
              <img src={partnerFourteenImg} alt="Nafed Bazaar" title="Nafed Bazaar" />
            </div>
          </div>
        </div>
      </div>
  )
}

export default Presence;