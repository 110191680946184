interface CustomWindow extends Window {
  fbq: Function;
}

declare let window: CustomWindow;

type TrackEvent = {
  event: string;
  isCustomTrack?: boolean;
  eventPayload: {
    type?: string;
    page?: string;
    device?: 'mobile' | 'desktop';
  };
};

const useFacebookPixel = () => {
  const regexp = /android|iphone|kindle|ipad/i;
  const isMobile = regexp.test(navigator.userAgent);
  const trackFBEvents = ({ event, eventPayload, isCustomTrack = false }: TrackEvent) => {
    eventPayload.type = eventPayload.type ? eventPayload.type : 'click';
    eventPayload.page = eventPayload.page ? eventPayload.page : 'homepage';
    eventPayload.device = isMobile ? 'mobile' : 'desktop';
    const trackMethod = isCustomTrack ? 'trackCustom' : 'track';
    window.fbq(trackMethod, event, eventPayload);
  };
  return { trackFBEvents };
};
export default useFacebookPixel;
