import { useState, useEffect } from "react";
import Services from "../../../axios";
import useGoogleAnalytics from "../../../hooks/useGoogleAnalytics";
import useFacebookPixel from "../../../useFacebookPixel";
import useMoengage from "../../../useMoengage";

const useSendMessage = () => {
  const [mobile, setMobile] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const regex = /[6-9]\d{0}/;
  const { clickEvent } = useGoogleAnalytics();
  const {trackEvent} = useMoengage();
  const {trackFBEvents} = useFacebookPixel()

  useEffect(() => {
    const timer = () => setTimeout(() => {
      if (msg) {
        setMsg("");
      }
    }, 60 * 1000);
    const timerId = timer();
    return () => {
      clearTimeout(timerId);
    };
  }, [msg]);

  const handleMessage = (msg: string) => {
    setMsg(msg);
  }
  function Send() {
    if (!mobile) {
      handleMessage("Mobile Number Required");
    } else if (mobile.length < 10 && (regex).test((mobile)))
      handleMessage("Mobile Number should not be less than 10 digits");
    else if (mobile.length > 10 && (regex).test((mobile)))
      handleMessage("Mobile Number should not exceed 10 digits");
    else if (!(/[6-9]\d{9}/).test((mobile))) handleMessage("Invalid Mobile Number");
    else {
      setLoading(true);
      clickEvent('sent_to_phone', 'click');
      trackEvent({event: "send_link_click", eventPayload: {}});
      trackFBEvents({isCustomTrack: true, event: "send_link_click", eventPayload: {}})
      Services.SMS.send({ mobile: Number(mobile) }).then((res) => {
        setLoading(false);
        if (!res.error) {
          handleMessage(`SMS Sent to your mobile number: ${mobile}`);
          setMobile("");
        } else {
          handleMessage(`Some error please try again later!`);
          setMobile("");
        }
      }).catch((err) => {
        setLoading(false);
        handleMessage(err.response.data.message);
      })
    }
  }

  useEffect(() => {
    if (mobile) {
      setMsg("");

    }
  }, [mobile]);



  return { mobile, setMobile, Send, msg, loading };
};
export default useSendMessage;
