export const Heading = 'Privacy Policy';
export const Description =
  'This document explains how Krishiacharya Technologies Private Limited (KTPL) collects, processes, stores, and/or shares any Personal Information (as defined below) and/or other information from or about the Users. WE VALUE OUR USERS’ PRIVACY AND SO WE HAVE PREPARED THIS PRIVACY POLICY DOCUMENT TO DEMONSTRATE THIS. By accessing and/or using the Platform, you consent to the collection, storage, disclosure, and other uses of your information per this Privacy Policy. As mentioned in the General Terms set out hereinabove, you must be at least of legal age to access the Platform and/or use the Services. PLEASE CAREFULLY READ THIS DOCUMENT BEFORE SUBMITTING ANY INFORMATION ON THE PLATFORM. We may update this statement from time to time by publishing a revised version on the Platform per the Privacy Policy. Please check this Privacy Policy document each time before you share Personal Information with us. KTPL may share the information (including your Personal Information) with our Affiliates, employees, directors, agents, contractors, business partners, and our successors or permitted assigns or any third-party service provider, to provide you access to the Platform and/or Services, and by registering on the Platform and/or for our Services, you provide your express consent to us that we may collect, store, share, transmit or otherwise use your information (including Personal Information) per this Privacy Policy in particular and these Terms in general.';
export const PolicyList = [
  {
    title: 'Collection',
    txt: <div className='text'></div>,
    points: [
      {
        text: (
          <div className='li_txt'>
            <b>Personal Information: </b>
            When you create an account or register with us, we collect
            information that can be used to identify you (
            <b>"Personal Information"</b>). Personal Information may include
            your email address, name, username, address, and phone number,
            Aadhar number, PAN Card number, and any other personally
            identifiable information that you may choose to provide us or that
            you choose to include in your account on the Platform. We may also
            collect Personal Information that you provide when you place Bids,
            make an offer or an Acceptance, or otherwise communicate with us or
            other Users on or through the Platform.
            <br /><br />
            THE PLATFORM WILL NOT BE AVAILABLE TO INDIVIDUALS OR ENTITIES NOT
            REGISTERED WITH KTPL. IF YOU ARE NOT REGISTERED, YOU WILL NOT BE
            ABLE TO USE THE PLATFORM.
          </div>
        ),
        subPoints: [],
        text2: <div></div>,
      },
      {
        text: (
          <div className='li_txt'>
            <b>Your Content and Communications: </b>
            Our Services permit you to interact with us through the notification
            system. We collect and store all of the comments, communications,
            and chats you post, transmit or generate on the Platform and/or
            through the Services. When you ask for assistance from KTPL for
            customer support, the contact information you provide will be
            collected, as well as the information which you post while using the
            Platform and/or the Services, such as, your user ID number, etc.,
            and any correspondence or information contained within.
          </div>
        ),
      },
      {
        text: (
          <div className='li_txt'>
            <b>Cookies: KTPL </b>may employ cookies to track your access to the
            Platform and/or Services. These technologies operate either by
            placing a small file that stores some information on your computer
            or mobile device; and/or by accessing the information on your
            device. KTPL uses cookies and similar technologies to recognize your
            device, for example by identifying your IP address, and to collect
            data such as your device’s model, operating system and screen size,
            other applications installed on your device, time and date, and
            other information about how you use the Platform and/or Services.
            KTPL uses this information (a) to store and access information; (b)
            to enable KTPL to provide you with more customized services, and (c)
            to collect other information about your use of our Services.
          </div>
        ),
      },
    ],
  },
  {
    title: 'KTPL’S USE AND DISCLOSURE OF INFORMATION',
    txt: <div className='text'></div>,
    points: [
      {
        text: (
          <div className='li_txt'>
            <b>
              Use of information You agree that KTPL and/or service providers on
              KTPL’s behalf may use the information they collect, including your
              Personal Information for:
            </b>
          </div>
        ),
        subPoints: [
          <div className='li_txt li_txt_sub'>contacting you for customer support;</div>,
          <div className='li_txt li_txt_sub'>
            sending you updates or information about the Platform and/or
            Services;
          </div>,
          <div className='li_txt  li_txt_sub'>
            managing your account and relationship with the Platform and/or
            Services, and improving your experience when you use it;
          </div>,
          <div className='li_txt  li_txt_sub'>
            improving the Services, research, surveying, and engaging with you,
            for example by sending you communications for these purposes;
          </div>,
          <div className='li_txt  li_txt_sub'>
            marketing and promotion of the Platform and/or Services and/or other
            products;
          </div>,
          <div className='li_txt  li_txt_sub'>
            personalizing and optimizing the Platform and/or Services;
          </div>,
          <div className='li_txt  li_txt_sub'>
            sharing promotional content and/or advertising; and
          </div>,
          <div className='li_txt  li_txt_sub'>
            creating reports, analysis, or similar services for research or
            business intelligence.
          </div>,
        ],
        text2: (
          <div className='li_txt'>
            <br />
            You may unsubscribe from some of these messages by sending an email
            to us at the email address set out in Clause 9 of this Part C below.
          </div>
        ),
      },
      {
        text: (
          <div className='li_txt'>
            <b>Information available</b> By using the Platform and/or the
            Services you make certain that your Personal Information will be
            available to other Users. For example, when a trade is closed on the
            Platform, KTPL provides the contact information of the execution
            profile or, in case such profile is not available, the commercial
            profile, to other Users. When you list an auction with your details
            on the Platform, such listing, post, and/or activity are shared with
            other Users who may then share this information with others through
            the Platform and/or Services or outside the Platform and/or
            Services. You acknowledge that you have no expectation of privacy
            about the auctions, Bids, Offers, Acceptance, Contract Confirmation,
            which are posted on the Platform.
            <br /><br />
            NOTWITHSTANDING THE ABOVE, LISTINGS/POSTINGS MADE ON THE PLATFORM
            WILL ONLY BE SHARED WITH OTHER USERS.
          </div>
        ),
        text2: <div></div>,
      },
      {
        text: (
          <div className='li_txt'>
            <b>
              Other transfers ANY DISCLOSURE IS ALWAYS ON A CONFIDENTIAL BASIS.
            </b>
            <br />
            If KTPL’s control is acquired by a person(s) other than the present
            promoters, we may share Personal Information with such person(s),
            but even they will have to store and use such information in
            compliance with this Privacy Policy. We may also disclose
            information for other purposes to the extent authorized and/or
            required by law or judicial order. Otherwise, we do not disclose
            information for any other purposes, unless it has your consent.
          </div>
        ),
        text2: <div></div>,
      },
    ],
  },
  {
    title: 'SECURITY OF YOUR PERSONAL INFORMATION',
    txt: (
      <div className='text'>
        {' '}
        KTPL takes all reasonable and sound steps to ensure that the information
        is protected against misuse, loss, unauthorized access, modification,
        and/or disclosure. KTPL adopts and applies appropriate data collection,
        storage, management practices, and security procedures to protect
        against unauthorized access, alteration, addition, deletion, disclosure,
        and/or destruction of a User’s personal information, including their
        username, email address, password, transaction information, and any
        other data stored on the Platform. However, no data storage or
        transmission over the Internet or other network can be guaranteed to be
        100% secure. Accordingly, KTPL does not guarantee that information will
        not be accessed, disclosed, altered, or destroyed by any breach of any
        of the abovementioned safeguards. To the extent applicable, KTPL
        complies with all applicable data protection laws, and you (the User)
        are duly bound to provide all reasonable assistance and information to
        KTPL about compliance with such laws.
      </div>
    ),
  },
  {
    title: 'ACCESS TO PERSONAL INFORMATION ',
    txt: (
      <div className='text'>
        {' '}
        You have the right to access the Personal Information we collect and
        hold about you. If at any time you would like to access or change your
        Personal Information, or you would like more information on our approach
        to protecting your privacy, please contact us at the contact details set
        out in Clause 9 of this Part C. You can opt-out of receiving certain
        promotional or marketing material by selecting the unsubscribe option
        contained within the emails providing such material or contacting us at
        the contact details set out at Clause 9 of this Part C. However, you
        cannot opt out of receiving all e-mails from us about your use of the
        Platform, such as e-mails about the status of your account.
      </div>
    ),
  },
  {
    title: 'DATA PROTECTION PRACTICES',
    txt: (
      <div className='text'>
        {' '}
        Our data is hosted in AWS infrastructure and the data is encrypted at
        rest and in transit using AES-256 Encryption Standards. We utilize
        network firewalls built into Amazon VPC frameworks to ensure further
        security. We use AWS Cloudwatch and AWS GuardDuty for monitoring the
        systems, access logs, and activity on our infrastructure.
      </div>
    ),
  },
  {
    title: 'ADOPTION OF STANDARD PRACTICES',
    txt: (
      <div className='text'>
        {' '}
        We have implemented standard security best practices have a
        comprehensively documented information security program. However, this
        is to further record and clarify, the Company is not required to comply
        with any specific ISO standard as per the Applicable Laws.
      </div>
    ),
  },
  {
    title: 'DATA RETENTION POLICY ',
    txt: (
      <div className='text'>
        {' '}
        We are committed to protecting and respecting your privacy and will
        comply with all applicable data protection laws in all our dealings with
        your personal data. We review and update our data retention policies
        regularly to protect data securely and ensure that it is not deleted
        prematurely, either inadvertently or maliciously.
        <br /><br />
      </div>
    ),
    points: [
      {
        text: (
          <div className='li_txt'>
            User Data Retention
            <ol type='a'>
              <li>
                Personal Data of the Users, such as but not limited to the below
              </li>
              <ol type='i'>
                <li>Full Name;</li>
                <li>Email address;</li>
                <li>Phone number;</li>
                <li>Physical Address;</li>
                <li>Id Photo;</li>
                <li>Id Proof details;</li>
                <li>Family Details;</li>
                <li>Gender;</li>
                <li>Age;</li>
                <li>Date / Place of Birth;</li>
                <li>Use of cookies on web;</li>
                <li>Bank Account details;</li>
                <li>Transaction Information;</li>
              </ol>
              <li className="li_data_retation">
                <div> 
                  <b>Geo- Location Information</b>: Use and store information
                  about your location- to provide features of our Service, to
                  improve and customise our offerings;
                </div>
              </li>
              <li className="li_data_retation">
                <div>
                  <b>Usage Information is captured</b> - our interactions with
                  the platform;
                </div>
              </li>
              <li className="li_data_retation">
                <div>
                  <b>Non-Identifying Information</b> - Whenever you visit our
                  website, we may collect non-identifying information, such as
                  referring URL, browser, operating system, and Internet Service
                  Provider;
                </div>
              </li>
              <li className="li_data_retation">
                <div>
                  We may give information to law enforcement if we are compelled
                  to by a court order, if there has been a violation of any
                  Indian laws or if a violation of the Terms of Service or
                  Privacy Policy has occurred;
                </div>
              </li>
              <li className="li_data_retation">
                <div>
                  We retain residual information in our backup and/or database.
                  Such information would be deleted subject to contractual
                  agreements, provided that region/country’s law permits the
                  same.
                </div>
              </li>
            </ol>
          </div>
        ),
      },
      {
        text: (
          <div className='li_txt'>
            Corporate Data Retention
            <ol type='a'>
              <li>
                Including but not limited to, business records, files, finances,
                taxes, legal and vendor partners, corporate announcements,
                shareholding patterns, both paper or electronic records;
              </li>
              <li>
                Excluding Customer (User) data and Employee Data; c. Protect
                data from destruction for a length of time till the servers are
                operated by the Company;d. Obtain approval of legal department
                to permanently delete the data after period mentioned above is
                over.
              </li>
            </ol>
          </div>
        ),
      },
      {
        text: (
          <div className='li_txt'>
            Employee Data Retention
            <ol type='i'>
              <li>
                Including but not limited to, memoranda, contracts, emails,
                expense records and performance reports;
              </li>
              <li>
                Email, Slack, Notion, HR data to be retained for period of in
                accordance with Applicable Laws;
              </li>
              <li>
                The policy to be reviewed periodically by the senior management.
                Accordingly suggest changes while facing practical difficulties
                or comply with legislations;
              </li>
              <li>
                Certain records to be kept for Perpetuity, unless mentioned
                otherwise:
              </li>
              <ol type='a'>
                <li>Board of Directors Records of minutes of meetings;</li>
                <li>Press Releases and Public Filings documents;</li>
                <li>Book of Accounts and Tax Records;</li>
                <li>
                  Employment Records including recruitment, employment and
                  personal information. Retained for 3 years from cessation of
                  employment;
                </li>
                <li>Intellectual Property Records;</li>
                <li>
                  Execution copies of all contracts should be retained for at
                  least 3 years following the expiry or termination of the
                  contracts.
                </li>
              </ol>
            </ol>
          </div>
        ),
      },
    ],
  },
  {
    title: 'TERM',
    txt: (
      <div className='text'>
        KTPL may retain the information (including Personal Information) for as
        long as is necessary to fulfill the purposes for which it was collected
        or as needed to provide, even after you have discontinued or deleted any
        account or have ended the provision of the Services, if retention of
        such information is reasonably necessary to comply with KTPL’s legal
        obligations, regulatory requirements and for resolving disputes between
        Users or for preventing fraud, for backup, archival, and/or audit
        purposes or any other use.
      </div>
    ),
  },
  {
    title: 'GENERAL TERMS',
    txt: (
      <div className='text'>
        Notwithstanding anything to the contrary in this Privacy Policy, KTPL
        may preserve or disclose your information: (a) to the extent reasonably
        necessary to comply with a law, regulation, or legal request; (b) to
        protect the safety of any person; (c) to address fraud, security or
        technical issues; or (d) to protect KTPL’s rights or property. However,
        nothing in this Privacy Policy is intended to limit any legal defenses
        or objections that you may have to a third party, including a
        government’s request to disclose your information. If any court or other
        competent authority finds any of this Privacy Policy to be invalid or
        unenforceable, the other terms of this Privacy Policy will not be
        affected.
      </div>
    ),
  },
  {
    title: 'CHANGES TO THIS PRIVACY POLICY',
    txt: (
      <div className='text'>
        The most recent version of this Privacy Policy will govern the use of
        the information (including Personal Information) on the Platform. We may
        revise or amend this Privacy Policy from time to time. If we decide to
        change this Privacy Policy, we will inform you by posting the revised
        Privacy Policy on the Platform. KTPL may also, but is not required to,
        notify you of changes to the Privacy Policy via email to the email
        address associated with your account. If you object to any changes to
        the Privacy Policy, you should immediately stop using the Platform
        and/or Services and close any related accounts. By continuing to access
        or use the Platform and/or Services after changes have become effective,
        you agree to be bound by the revised Privacy Policy.
      </div>
    ),
  },
  {
    title: 'COMPLAINT & GRIEVANCE MECHANISM',
    txt: (
      <div className='text'>
        To Address the queries/concerns of the users we have defined Complaint &
        Grievance Mechanism.
        <br /><br />
      </div>
    ),
    points: [
      {
        text: (
          <div className='li_txt'>
            <b> Level 1: </b>Users can reach us through the “contact us” feature
            on the mobile application or can write to us at <a href='mailto:service@gojust.life'>‘service@gojust.life’</a>
            .
          </div>
        ),
      },
      {
        text: (
          <div className='li_txt'>
            <b>Level 2:</b> If the user query/concerns are not addressed to the
            expected levels, then they can reach the Grievance Officer- Mr.
            Jitender Bedwal. Email id: <a href='mailto:grievanceofficer@bijak.in'>grievanceofficer@bijak.in;</a> Grievance
            officer acknowledges the receipt of any user query/concerns within
            48 hours and redresses the complaint within 1 month from the date of
            receipt of the complaint.
          </div>
        ),
      },
      {
        text: (
          <div className='li_txt'>
            <b> Level 3: </b>In the unlikely event that your issue remains
            unresolved to your satisfaction despite escalating to our Grievance
            Officer, you can reach out to our Nodal Officer Mr. Nukul Upadhye
            – <a href='mailto:grievanceofficer@bijak.in'>grievanceofficer@bijak.in</a> We will respond within 3 business days from
            the date of receipt of your email.
          </div>
        ),
      },
    ],
  },
];
