import React from "react";
import "./style.scss";
import itemOneImg from "../../../assets/images/JustRetailer/whyJust/whyjust1.svg";
import itemTwoImg from "../../../assets/images/JustRetailer/whyJust/whyjust2.svg";
import itemThreeImg from "../../../assets/images/JustRetailer/whyJust/whyjust3.svg";

const WhyJust = () => {
  return (
    <div className="justR_whyJust">
      <div className="container">
        <h2>Why Just?</h2>
        <div className="list">
          <div className="item">
            <img src={itemOneImg} alt="PAN-India" />
            <h3>PAN-India</h3>
            <p>Our Pan-India Sourcing linkages enable us to procure the best and the most premium products.</p>
          </div>
          <div className="item">
            <img src={itemTwoImg} alt="Quality Check" />
            <h3>Quality Check</h3>
            <p>
              Every fruit & vegetable goes through stringent QA checks and state-of-the-art sorting & grading processes to bring you healthy, fresh, and
              standardised produce.
            </p>
          </div>
          <div className="item">
            <img src={itemThreeImg} alt="Safe and Hygiene Packaging" />
            <h3>Safe and Hygiene Packaging</h3>
            <p>Our produce is conveniently packed in moisture-controlled packets, assuring safety, hygiene, and ease of use</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyJust;
