import user1 from "./images/user1.webp";
import user2 from "./images/user2.webp";
import user3 from "./images/user3.webp";
import user4 from "./images/user4.webp";
import user5 from "./images/user5.webp";
import user6 from "./images/user6.webp";



export const Heading = "Hear What Our Customers Have To Say!";
export const SubHeading ="Delhi & Gurugram";


export const TESTIMONIAL_ARRAY = [
  {
    id: 0,
    name: 'Pradnya Patil',
    content: `I have ordered fruits and vegetables from JUST Fresh and I am very impressed with the quality. Now I don't have to leave the house to stock our fridge with healthy food. Instead, I can have it conveniently delivered to my home. Thanks for your service.`,
    image: user1          
  },
  {
    id: 1,
    name: 'Rahul Deshpande',
    content: `Every time I receive an order from JUST Fresh, it feels like I'm receiving an amazing gift. Always filled with delicious goodness and my family loves it.`,
    image: user2     
  },
  {
    id: 2,
    name: 'Ramya Krishnan',
    content: `I’m so glad that I found JUST Fresh, it’s definitely farm-fresh. All the fruits and vegetables I’ve ordered so far are superior in taste, quality and freshness compared to what is available in the market.`,
    image: user3          
  },
  {
    id: 3,
    name: 'Vimla Puri',
    content: `I usually order my vegetables from online platforms. But it’s great to get fruits and vegetables delivered straight from nearby farms! I have noticed that the rates are reasonable and there is a huge variety of products. Also, the delivery is on-time. I order the night before and I get it the very next morning!`,
    image: user4          
  },
  {
    id: 4,
    name: 'Abrar Sofi',
    content: `There's no store near my house from where I can buy exotic fruits and vegetables. But with JUST Fresh, I can buy exotic produce at affordable rates and have it delivered straight from the farms!`,
    image: user5          
  },
  {
    id: 5,
    name: 'Kevin Anthony',
    content: `Our family loves to cook and with JUST Fresh, we can now experiment with so many different cuisines because even rare herbs and vegetables are available. And they all taste delicious. Plus, JUST Fresh app always has great offers.`,
    image: user6          
  },
];

export const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
};

export const thumbSliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  centerMode: true,
  focusOnSelect: true,
  centerPadding:'20px',
  arrows: false,
  responsive: [
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 1
    }
  },
  {
    breakpoint: 350,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 1,
      centerMode: true,
    }
  }
]
};