
import "./styles.scss";
import BenefitsSlider from './slider';
import Ingredients from "./ingredients";
import Products from "./products";

const Benefits = () => {

  return (
    <div id="benefits" className="mt-8 scroll">
      <Products/>
      <Ingredients/>
      <BenefitsSlider/> 
    </div>
  )
}

export default Benefits
