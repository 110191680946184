import "./styles.scss";
import { Link } from "react-router-dom";
import {
  JUSTLogo,
  Copyright,
  FollowTxt,
  PoweredByTxt,
  BIJAKLogo,
  FacebookLink,
  FacebookLogo,
  InstaLink,
  InstaLogo,
  callIcon,
  emailIcon,
  locationIcon,
  callInfo,
  emailInfo,
  locationInfo,
} from "./constants";
import GooglePlayStoreIcon from "../Common/GooglePlayStoreIcon";
import useMoengage from "../../useMoengage";
import useFacebookPixel from "../../useFacebookPixel";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";

const Footer = () => {
  const { trackEvent } = useMoengage();
  const {trackFBEvents} = useFacebookPixel();
  const {clickEvent} = useGoogleAnalytics();
  const justLogo = <img src={JUSTLogo} alt="JUST Fresh - Buy Fruits & Vegetables" title="JUST Fresh - Buy Fruits & Vegetables" className="just_logo" />;

  const handletrackEvent = () => {
    trackEvent({ event: "social_icon_click", eventPayload: {} });
    trackFBEvents({event: "social_icon_click", isCustomTrack: true, eventPayload: {}});
  };

  const handleFbClick = () => {
    handletrackEvent();
    window.open(FacebookLink, "_blank")?.focus();
  };

  const handleInstaClick = () => {
    handletrackEvent();
    window.open(InstaLink, "_blank")?.focus();
  };

  const handleJustRetailerClick = () => {
    trackEvent({event:"just_retailer_click", eventPayload:{}});
    clickEvent('just_retailer_click', 'click');
    trackFBEvents({isCustomTrack: true, event: "just_retailer_click", eventPayload: {}});
  }

  return (
    <div className="footer" data-scroll-index="4" id="footer">
      <div className="container">
        <Link to="/" onClick={() => window.scrollTo(0, 0)}>
          {justLogo}
        </Link>
        <div className="flex_conatiner">
          <div className="left_section">
            <div className="links_wrpper">
              <div className="link">
                <img src={callIcon} alt="call icon" className="call_icon" />
                <a href="tel:022-35500402">{callInfo}</a>
              </div>
              <div className="link">
                <img src={emailIcon} alt="email icon" className="email_icon" />
                <a href="mailto:service@gojust.life">{emailInfo}</a>
              </div>
              <div className="link">
                <img src={locationIcon} alt="location icon" className="location_icon" />
                <a href="https://goo.gl/maps/vsdbNiaC7MvBotJ26" target="_blank" rel="noreferrer">{locationInfo}</a>
              </div>
            </div>
            <div className="link follow_us">
              {FollowTxt}
              <div>
                <img src={FacebookLogo} alt="facebook" className="fb_logo" onClick={handleFbClick} />
                <img src={InstaLogo} alt="facebook" className="fb_logo" onClick={handleInstaClick} />
              </div>
            </div>
            <div className="powered_by">
              {PoweredByTxt} 
               <span><img src={BIJAKLogo} alt="bijaklogo" className="bijak_logo" /></span> 
            </div>
            <div className="rights_reserved_web">{Copyright}</div>
          </div>
          <div className="right_section">
            <div className="page_link">
              <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                About
              </Link>
              <Link to="/just-retailer" onClick={handleJustRetailerClick}>
                Just Retailer
              </Link>
              <Link className="footer_terms_conditions" to="/terms">
                Terms & Conditions
              </Link>
              <Link to="/privacy">Privacy Policy</Link>
              <div className="mob_follow_us">
                {FollowTxt}
                <div>
                  <img src={FacebookLogo} alt="facebook" className="fb_logo" onClick={handleFbClick} />
                  <img src={InstaLogo} alt="facebook" className="fb_logo" onClick={handleInstaClick} />
                </div>
              </div>
            </div>
            <div className="app_link">
              <p className="get_the_app">Get the App</p>
              <GooglePlayStoreIcon isFooter={true} appLink="https://gojust.store/Get_App3" from="footer" platform="android" event="get_on_gplay_click" />
              <GooglePlayStoreIcon
                isFooter={true}
                appLink="https://apps.apple.com/us/app/just-fresh-buy-fruit-vegetable/id1634024358"
                from="footer"
                platform="ios"
                event="get_on_iOS"
              />
              <div className="rights_reserved_mob">{Copyright}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
