import GooglePlayStoreIcon from "../../Common/GooglePlayStoreIcon";
import { Heading, FeaturesData } from "./constants";
import Pictures from "./pictures";
import "./styles.scss";

const Features = () => {
  return (
    <div id="features" className="feature-scroll">
      <div className="container" data-scroll-index="1">
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="reaches-heading">{Heading}</h2>
          <div className="reaches-playstore">
            <GooglePlayStoreIcon appLink="https://gojust.store/Get_App1" from="hero_area" platform="android" event="get_on_gplay_click" />
            <GooglePlayStoreIcon
              appLink="https://apps.apple.com/us/app/just-fresh-buy-fruit-vegetable/id1634024358"
              from="hero_area"
              platform="ios"
              event="get_on_iOS"
            />
          </div>
        </div>
        <div className="row features-row">
          {FeaturesData.map((item, index) => (
            <div className="col-md-6 col-lg-3 col-sm-12 features-col" key={index}>
              <div className="features-icon">{item.img}</div>
              <div className="features-text">
                <h4 className="features-title">{item.title}</h4>
                <div className="features-content">{item.content}</div>
              </div>
            </div>
          ))}
        </div>
        <Pictures />
      </div>
    </div>
  );
};
export default Features;
