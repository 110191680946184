import React from "react";
import "./style.scss";
import ImageTop from "../../../assets/retailer/hero/BG-top.jpg";
import ImageBottom from "../../../assets/retailer/hero/BG-bottom.jpg";

const JustRetailerHero = () => {
  // eslint-disable-next-line no-restricted-globals
  const width = screen.width;
  return (
    <div className="justR_hero">
      <img src={ImageTop} alt="just retailer hero" className="hero_img top_img" />
      <div className="container">
        <h2 className="hero_heading">
          <strong>Premium {width > 500 ? <span>|</span> : <br/>}</strong> Fruits & Vegetables
        </h2>
        <p className="hero_description">
          Your go-to app for buying 100+ varieties of online farm fresh fruits and vegetables for your retail shop. Being one of the best online B2B
          marketplaces for fresh vegetable retailers across Delhi NCR, JUST Retailer promises premium quality produce.
        </p>
      </div>
      <img src={ImageBottom} alt="just retailer hero" className="hero_img bottom_img" />
    </div>
  );
};

export default JustRetailerHero;
