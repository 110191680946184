import FreshVeg from "../../../assets/retailer/fresh_veg.webp";
import FreshFruit from "../../../assets/retailer/orange.webp";
import FreshVegMob from "../../../assets/retailer/fresh_veg_mob.webp";
import FreshFruitMob from "../../../assets/retailer/orange_mob.webp";
import "./style.scss";

const JustProducts = () => {
  // eslint-disable-next-line no-restricted-globals
  let width = screen.width
  return (
    <div className="justR_products container">
        <h2 className="heading">Just online fresh products</h2>
        <div className="img_container">
          {width < 1001 && <div>
            <img src={FreshVegMob} alt="fresh vegetables" />
            <img src={FreshFruitMob} alt="fruits fruits" />
            </div>}
          <div className="img_wrapper">
            <img src={FreshVeg} alt="fresh vegetables" />
            <p className="img_title"> FRESH VEGETABLES </p>
          </div>
          <div className="img_wrapper">
            <img src={FreshFruit} alt="fruits fruits" />
            <p className="img_title"> FRESH FRUITS </p>
          </div>
        </div>
    </div>
  );
};

export default JustProducts;
