import { IngredientsHeading, IngredientsSubheading, IngredientsList } from "./constant";
import MobileImg from "../../../assets/images/benefits/benefits_mobile.webp";
const Ingredients = () => {
  return (
    <div className="mt-8">
      <div className="container">
      <h2 className="iheading ">{IngredientsHeading}</h2>
      <div className="isubheading">{IngredientsSubheading}</div>
      <div className="imobile row">
        <div className="col-md-12 col-lg-6 m-auto order-lg-1 order-2">
          {IngredientsList.map((item, index) => (
            <div className="ilist" key={index}>
              <img src={item.img} alt={item.title} />
              <div className="content">
                <h3 className="title">{item.title}</h3>
                <div className="subtitle">{item.subtitle}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="col-md-12 col-lg-6 text-center order-lg-2 order-1">
          <img src={MobileImg} alt="Home Delivery App for Vegetables & Fruits in Delhi, Noida, Gurgaon" title="Home Delivery App for Vegetables & Fruits in Delhi, Noida, Gurgaon" className="mobile_img" />
        </div>
      </div>
    </div>
    </div>
  );
};
export default Ingredients;
