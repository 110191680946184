import "./styles.scss";
import { Info } from "./constants";
import mobile1 from "../../../assets/images/footer/info-mobile.webp";
import mobile2 from "../../../assets/images/footer/small-mobile-info.webp";

const InfoBox = () => {
  return (
    <div className="infobox mt-8">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <div className="iheading">{Info}</div>
          </div>
          <div className="col-md-12 col-lg-6 p-0">
            <img src={mobile1} alt="Easy to Access Vegetable & Fruit Delivery App: Just Fresh" title="Easy to Access Vegetable & Fruit Delivery App: Just Fresh" className="large" />
          </div>
        </div>
      </div>
      <img src={mobile2} className="small" alt="Easy to Access Vegetable & Fruit Delivery App: Just Fresh" title="Easy to Access Vegetable & Fruit Delivery App: Just Fresh" />
    </div>
  );
};
export default InfoBox;
