import { useState } from "react";
import Slider from "react-slick";
import cx from "classnames";
import {
  Heading,
  SubHeading,
  TESTIMONIAL_ARRAY,
  sliderSettings,
  thumbSliderSettings,
} from "./constants";
import "./styles.scss";
import Quote1 from "../../../assets/images/testimonials/quote-1.webp";
import Quote2 from "../../../assets/images/testimonials/quote-2.webp";
const Testimonials = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const slider2Settings = {
    ...thumbSliderSettings,
    afterChange: (current: number) => setActiveIndex(current),
  };

  return (
    <div id="testimonials" className="container p-0 tContainer mt-8 test-scroll" data-scroll-index="3">
      <div className="container" >
        <h2 className="theading text-center">{Heading}</h2>
        <div className="tsubheading text-center">{SubHeading}</div>
      </div>
      <Slider
        {...sliderSettings}
        asNavFor={nav2}
        ref={(slider1: any) => setNav1(slider1)}
      >
        {TESTIMONIAL_ARRAY.map((item: any) => (
          <div className="tcard" key={item.id}>
            <div className="ttext">
              <img src={Quote1} alt="quote" className="quote1" />

              {item.content}
              <img src={Quote2} alt="quote" className="quote2" />
            </div>
            <div className="tname">{item.name}</div>
          </div>
        ))}
      </Slider>
      {/* <div className="tArrow" /> */}
      <Slider
        {...slider2Settings}
        asNavFor={nav1}
        ref={(slider2: any) => setNav2(slider2)}
        className="thumbSliderWrapper"
      >
        {TESTIMONIAL_ARRAY.map((item: any, index: number) => {
          return (
            <div
              className={cx("tThumbSlider", {
                active: activeIndex === item.id,
              })}
              key={item}
            >
              <img src={item.image} alt={item.name} title={item.name} />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
export default Testimonials;
