// Slider
import DailyDelivery from "./images/DailyDelivery.webp";
import AllOrder from "./images/AllOrder.webp";
import CashOnDelivery from "./images/CashOnDelivery.webp";
import Wallet from "./images/Wallet.webp";
// Products
import product1 from "../../../assets/images/benefits/product1.webp";
import product2 from "../../../assets/images/benefits/product2.webp";
import product3 from "../../../assets/images/benefits/product3.webp";
import product4 from "../../../assets/images/benefits/product4.webp";
import product5 from "../../../assets/images/benefits/product5.webp";
import product6 from "../../../assets/images/benefits/product6.webp";
// Benefits
import nectar_sweet from "../../../assets/images/benefits/nectar-sweet.webp";
import crispy_vegetable from "../../../assets/images/benefits/crispy-vegetables.webp";
import exotic_produce from "../../../assets/images/benefits/exotic-produce.webp";
import fragrant_herbs from "../../../assets/images/benefits/fragrant-herbs.webp";
  
// Slider
export type BenefitTypes = {
  id: number;
  name: string;
  content: string;
  icon: string;
  altTag: string,
}[];
export const benefitList: BenefitTypes = [
  {
    id: 0,
    name: "Safe & On time Delivery",
    content:
      "We deliver all seven days of the week. Place your order, select your morning slot, and get ready for farm-fresh produce.",
    icon: DailyDelivery,
    altTag: 'Best Place to Buy Fruits and Vegetables Online',
  },
  {
    id: 1,
    name: "We Fulfill All Order Sizes",
    content:
      "Whether you need just a few items or a larger quantity, we're happy to help you out.",
    icon: AllOrder,
    altTag: 'Woww. Online Sabji Delivery at Best Prices',
  },
  {
    id: 2,
    name: "Flexible Payment Options",
    content:
      "We accept all payment methods, including Card, UPI, Netbanking, Wallet, and Sodexo.",
    icon: CashOnDelivery,
    altTag: 'Free Home Delivery Vegetables and Fruits: Just Fresh App',
  },
  {
    id: 3,
    name: "JUST Fresh Wallet",
    content: "Easily access reward amounts and refunds in your JUST wallet. ",
    icon: Wallet,
    altTag: 'Online Fruits and Vegetables Shopping with Easy Payments',
  },
];
// Products
export const ProductsHeading = "Everything You Need, To Cook Up A Feast";
export const ProductsSubheading =
  "We have 100+ varieties of fruits, vegetables, herbs & exotics - delivered straight to you from reputed farms!";
export const ProductsList = [
  {
    img: product1,
    txt: "We’re only a click away - download app, browse and order today",
    altTag: 'Fruits Shopping Online at Just Fresh'
  },
  {
    img: product2,
    txt: "Fruits fresh from the farms taste different. Order now and find out",
    altTag: 'Order Fruits Online at Just Fresh'
  },
  {
    img: product3,
    txt: "Plucked, cleaned, sorted - we do all the heavy lifting",
    altTag: 'Online Delivery of Fruits by Just Fresh'
  },
  {
    img: product4,
    txt: "Hygienic packing, safe delivery - we put you first",
    altTag: 'Buy Vegetables Online at Just Fresh'
  },
  {
    img: product5,
    txt: "Sample new things with exciting offers - download app to find out",
    altTag: 'Vegetables Online Shopping at Just Fresh'
  },
  {
    img: product6,
    txt: "No days off - we deliver daily",
    altTag: 'Fresh Vegetables Online Available at Just Fresh'
  },
];
// Benefits
export const IngredientsHeading = "The Best Ingredients At Your Fingertips";
export const IngredientsSubheading =
  "Delicious produce is now just a click away! Download JUST Fresh app, browse our vast selection and place your order - it’s that simple!";
export const IngredientsList = [
  {
    img: nectar_sweet,
    title: "Nectar-sweet Fruits",
    subtitle:
      "Enjoy the taste of delicious seasonal fruits from nearby farms.",
  },
  {
    img: crispy_vegetable,
    title: "Crispy Vegetables",
    subtitle:
      "Fresh vegetables to take your dishes to the next level.",
  },
  {
    img: exotic_produce,
    title: "Exotic Produce",
    subtitle:
      "Cook up global cuisine in your kitchen with fresh exotic fruits and vegetables.",
  },
  {
    img: fragrant_herbs,
    title: "Fragrant Herbs",
    subtitle:
      "Say goodbye to wilted and dry herbs from the market. Farm fresh herbs just hit different!",
  },
];
