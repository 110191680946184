import Slider from "react-slick";
import ImageOne from "../../../assets/retailer/justApp/Screenshot_1.webp";
import ImageTwo from "../../../assets/retailer/justApp/Screenshot_2.webp";
import ImageThree from "../../../assets/retailer/justApp/Screenshot_3.webp";
import ImageFour from "../../../assets/retailer/justApp/Screenshot_4.webp";
import ImageFive from "../../../assets/retailer/justApp/Screenshot_5.webp";
import "./style.scss";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: true,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        arrows: false,
        slidesToShow: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 2,
        infinite: true,
        dots: true,
        autoplay: false,
      },
    },
    {
      breakpoint: 575,
      settings: {
        arrows: false,
        centerMode: true,
        slidesToShow: 1,
        dots: true,
        infinite: true,
        centerPadding: "20px",
        autoplay: false,
      },
    },
  ],
};

const sliderData = [
  {
    imgSrc: ImageOne,
    altText: "Just Retailer: Fruit and Veg Market Wholesale",
  },
  {
    imgSrc: ImageFour,
    altText: "Just Retailer: Order Online Vegetable Wholesale Supplier",
  },
  {
    imgSrc: ImageThree,
    altText: "Just Retailer: Buy Online Vegetables & Fruits for your Business",
  },
  {
    imgSrc: ImageTwo,
    altText: "Just Retailer: Make Easy Payments on your Orders",
  },
  {
    imgSrc: ImageFive,
    altText: "Just Retailer: Your Online Vegetable Vendor",
  },
];

const JustApp = () => {
  return (
    <div className="justR_justApp">
      <div className="container">
        <h2 className="justapp_heading">
          <div className="for_web"><strong>FRESH PRODUCE. </strong>QUICK SERVICE. TIMELY DOORSTEP DELIVERY.</div>
          <div className="for_mob"><strong>FRESH PRODUCE. </strong>QUICK SERVICE. TIMELY DOORSTEP DELIVERY.</div>
        </h2>
        <Slider {...settings} className="just_slick_slider">
          {sliderData.map((item) => (
            <img src={item.imgSrc} alt={item.altText} title={item.altText} className="slider_img" />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default JustApp;
