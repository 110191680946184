import { PlaystoreLogo, AppStoreLogo } from "../../svg";
import PlayStoreImg from "../../assets/images/footer/Footer_Playstore.webp";
import AppStoreImg from "../../assets/images/footer/footer_app_store.png";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";
import useMoengage from "../../useMoengage";
import { useState, useEffect } from "react";
import useFacebookPixel from "../../useFacebookPixel";

interface AppBtnData {
  isFooter?: boolean;
  appLink: string;
  from: string;
  platform: string;
  event: string;
  pageName?: string;
}

const GooglePlayStoreIcon = ({ isFooter, appLink, from, platform, event, pageName }: AppBtnData) => {
  const { clickEvent } = useGoogleAnalytics();
  const {trackEvent} = useMoengage();
  const {trackFBEvents} = useFacebookPixel();
  const [btnText, setBtnText] = useState({playStore: "Get It On App Store", ios: "Get It On Google Play"})

  const handleOnClick = () => {
    clickEvent("download_app", "click", from);
    trackEvent({event, eventPayload: pageName ? {page: pageName} : {} });
    trackFBEvents({event, isCustomTrack: true, eventPayload: pageName ? {page: pageName} : {}});
  };

  // eslint-disable-next-line no-restricted-globals
  const width: number = screen.width;

  useEffect(() => {
    if(width > 1200){
      setBtnText({
        playStore: "Get It On App Store", 
        ios: "Get It On Google Play"
      })
    }else{
      setBtnText({
        playStore: "", 
        ios: ""
      })
    }
  }, [width])
  

  return (
    <div>
      {!isFooter ? (
        <a href={appLink} className="navBtnLink" target="_blank" rel="noreferrer" onClick={handleOnClick}>
          {platform === "ios" ? (
            <span>
             {btnText.playStore} <AppStoreLogo />
            </span>
          ) : (
            <span>
              {btnText.ios} <PlaystoreLogo />
            </span>
          )}
        </a>
      ) : (
        <a href={appLink} className="playstore d-flex" target="_blank" rel="noreferrer" onClick={handleOnClick}>
          <img src={platform === "ios" ? AppStoreImg : PlayStoreImg} alt="playstore" />
          {/* <img src={PlayStoreImgHover} alt="playstore" /> */}
        </a>
      )}
    </div>
  );
};
export default GooglePlayStoreIcon;
