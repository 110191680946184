import {
  Heading,
  SubHeading,
  HeroImg,
  HeroImgMob,
} from "./constants";
import "./styles.scss";
import { useEffect, useState } from "react";
import useGoogleAnalytics from "../../../hooks/useGoogleAnalytics";
import { PlaystoreLogo, AppStoreLogo } from "../../../svg";
import useMoengage from "../../../useMoengage";
import useFacebookPixel from "../../../useFacebookPixel";

const Hero = () => {
  const {trackEvent} = useMoengage();
  const {clickEvent} = useGoogleAnalytics();
  const {trackFBEvents} = useFacebookPixel();
  const [scroll, setScroll] = useState<number>(0);
  const [platform, setPlatform] = useState<string>('')
  // eslint-disable-next-line no-restricted-globals
  const width = screen.width;

  useEffect(() => {
    let isActive = true;
    if (isActive) {
      const playstore = document.getElementById("playstore");
      const sticky = playstore?.offsetTop || 0;
      window.addEventListener("scroll", () => {
        if (window.pageYOffset > sticky) {
          playstore?.classList.add("sticky");
          playstore?.classList.remove("non-sticky");
        } else {
          playstore?.classList.remove("sticky");
          playstore?.classList.add("non-sticky");
        }
      });
      playstore?.classList.add("non-sticky");
    }
    return () => {
      isActive = false;
    };
  }, []);

  const onOrderNowBtnClick = () => {
    trackEvent({event: "order_now_click", eventPayload:{}});
    trackFBEvents({event: "order_now_click", isCustomTrack: true, eventPayload: {}})
    clickEvent('order_now_click', 'click');
    window.open(process.env.REACT_APP_D2C_WEB_APP_URL, '_blank');
  }

  useEffect(()=>{
    window.addEventListener("scroll", () => setScroll(window.scrollY));
    if(/iPhone|iPad|iPod/i.test(navigator.userAgent)){
      setPlatform('ios')
    }else{
      setPlatform('android')
    }
  },[])

  return (
    <div className="container hero-container text-center" id="top">
      <div className="hero_wrapper">
        <div className="left_section">
          <h1 className="hero-heading">{Heading}</h1>
          <h4 className="hero-subheading">{SubHeading}</h4>
          <img src={HeroImgMob} alt="Just Fresh: Buy Fruits and Vegetables Online with many Benefits" />
          <button onClick={onOrderNowBtnClick} className="order_now_btn">
            Order Now
          </button>
        </div>
        <div className="right_section">
          <img src={HeroImg} alt="Just Fresh: Buy Fruits and Vegetables Online with many Benefits" />
        </div>
      </div>

      <div className={`no_display_sticky_btn ${width < 992 && scroll > 400 && "stick_btn_wrapper"}`}>
        {platform === "ios" ? (
          <AppLinkBtn appLink="https://apps.apple.com/us/app/just-fresh-buy-fruit-vegetable/id1634024358" platform="ios" />
        ) : (
          <AppLinkBtn appLink="https://gojust.store/Get_App1" platform="android" />
        )}
      </div>
    </div>
  );
};

export default Hero;

const AppLinkBtn = ({ appLink, platform }: any) => {
  const { clickEvent } = useGoogleAnalytics();
  const {trackEvent} = useMoengage();
  const {trackFBEvents} = useFacebookPixel();
  const handleOnClick = () => {
    clickEvent("download_app", "click", "mobile_sticky_btn");
    trackEvent({event: `${platform === "ios" ? "get_on_ios" : "get_on_gplay"}`, eventPayload:{}});
    trackFBEvents({event: `${platform === "ios" ? "get_on_ios" : "get_on_gplay"}`, isCustomTrack: true, eventPayload: {}})
  };
  return (
    <div className="sticky_btn">
      <a href={appLink} target="_blank" rel="noreferrer" onClick={handleOnClick} className="sticky_link">
        {platform === "ios" ? (
          <span>
            Get It On App Store <AppStoreLogo />
          </span>
        ) : (
          <span>
            Get It On Google Play <PlaystoreLogo />
          </span>
        )}
      </a>
    </div>
  );
};
