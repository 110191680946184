import { useRef, useState } from "react";
import Slider from "react-slick";
import cx from "classnames";

import "./sliders.scss";
import { benefitList, BenefitTypes } from "./constant";
import DownloadNow from "../../Common/DownloadNow";

const BenefitsSlider = () => {
  const sliderRef = useRef<any>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    afterChange: (current: number) => setActiveIndex(current),
  };
  const handleSlider = (index: number) => {
    sliderRef.current.slickGoTo(index);
    setActiveIndex(index);
  };
  return (
    <div className="text-center container benefitsSlider mt-8">
      <Slider ref={sliderRef} {...settings}>
        { benefitList.map((item: BenefitTypes[0]) => {
          return (
            <div className="sliderContent" key={item.id}>
              <div className="bSliderImage">
                <img className='bImage' src={item.icon} alt={item.altTag} title={item.altTag} />
              </div>
              <div className='bContent'>
                <h3 className='bgTitle text-start fw-bold  mx-2 h1'>{item.name}</h3>
                <p className='dMsg text-start  mx-2'>{item.content}</p>
                <div className="text-start mx-2 download_btn_wrapper">
                  <DownloadNow playStoreLink="https://gojust.store/Get_App2" from="app_features" />
                </div>
              </div>
            </div>
          )
        }) }
      </Slider>
      <ul className='dots'>
        {benefitList.map((item: BenefitTypes[0]) => <li key={item.id} className={cx({ 'active': activeIndex === item.id })} onClick={() => handleSlider(item.id)} ></li> )}
      </ul>
    </div>
  );
};

export default BenefitsSlider;
