import { Fragment, useEffect } from 'react';
import { Heading, PolicyList, Description } from './constants';
import { Helmet } from 'react-helmet';
import './styles.scss';
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='privacy container-fluid'>
      <Helmet>
        <title>Just Fresh | Privacy Policy</title>
        <meta name="description" content="Read our privacy policy to know how Just Fresh collects, processes, stores, and/or shares any information about its users"/>
        <link rel="canonical" href="https://www.justfreshlife.com/privacy" />
      </Helmet>
      <div className='heading'>{Heading}</div>
      <div className='list row justify-content-center'>
        <div className='col-xl-8 mb-5'>
          <div className='li_txt text-justify'> {Description}</div>
          <ol>
            {PolicyList.map((item, index) => (
              <div key={index}>
                <li className='title'>{item.title}</li>
                {item.txt}
                <ol type='1'>
                  {item.points &&
                    item.points.map((p, index) => (
                      <Fragment key={index}>
                        <li className='li_text'>
                          {p.text}
                        </li>
                        <ol type='1'>
                          {p.subPoints &&
                            p.subPoints.map((s, index) => (
                              <li className='li_text' key={index}>
                                {s}
                              </li>
                            ))}
                        </ol>
                        {p.text2}
                      </Fragment>
                    ))}
                </ol>
              </div>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
