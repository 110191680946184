import { useEffect } from "react";
import { Heading, TermsConditionsB2b } from "./constants";
import { Helmet } from 'react-helmet'; 
import "./styles.scss";
const TermsAndConditions = () => {
 useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="terms_conditions container-fluid">
      <Helmet>
        <title>Just Fresh | Terms & Conditions</title>
        <meta name="description" content="Before ordering on Just Fresh app, please read & accept the terms and conditions provided under this section"/>
        <link rel="canonical" href="https://www.justfreshlife.com/terms" />
      </Helmet>
      <div className="heading">{Heading}</div>
      <div className="list row justify-content-center">
        <div className="col-xl-8">
          <ol>
            {TermsConditionsB2b.map((item, index) => (
              <div key={index}>
                <li className="title">{item.title}</li>
                <ol>
                  {item.points.map((p, index) => (
                    <div key={index}>
                      <li className="text">{p.txt}</li>
                      {p.subPoints && (
                        <ol type="1">
                          {p.subPoints.map((sp, index) => (
                            <li className="text" key={index}>
                              {sp}
                            </li>
                          ))}
                        </ol>
                      )}
                    </div>
                  ))}
                </ol>
              </div>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};
export default TermsAndConditions;
