import { RightArrow } from "../../svg";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";

const DownloadNow = ({playStoreLink, from}: any) => {
  const { clickEvent } = useGoogleAnalytics();

  const handleOnClick = () => {
    clickEvent('download_app', 'click', from);
  }

  return (
    <a href={playStoreLink} className="picture-download" target="_blank" rel="noreferrer" onClick={handleOnClick}>
      Download Now <RightArrow />
    </a>
  );
};
export default DownloadNow;
