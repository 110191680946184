export const PlaystoreLogo = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.363538 0.316055C0.107963 0.620703 -0.0210855 1.01125 0.00283268 1.40769V18.5923C-0.021155 18.9888 0.107901 19.3794 0.363538 19.684L0.421214 19.74L10.0772 10.1136V9.88646L0.421214 0.259644L0.363538 0.316055Z"
      fill="url(#paint0_linear_214_8172)"
    />
    <path
      d="M13.2966 13.3237L10.0781 10.1135V9.88641L13.2974 6.677L13.3699 6.71826L17.1833 8.87845C18.2726 9.49541 18.2726 10.5049 17.1833 11.1223L13.3699 13.2824L13.2966 13.3237Z"
      fill="url(#paint1_linear_214_8172)"
    />
    <path
      d="M13.3707 13.2821L10.0789 10L0.365234 19.6839C0.587183 19.8791 0.870616 19.9907 1.16643 19.9994C1.46224 20.0081 1.75178 19.9134 1.9849 19.7316L13.3707 13.2821Z"
      fill="url(#paint2_linear_214_8172)"
    />
    <path
      d="M13.3707 6.71789L1.9849 0.268312C1.75178 0.0865624 1.46224 -0.00816166 1.16643 0.000551733C0.870616 0.00926512 0.587183 0.120867 0.365234 0.316021L10.0789 10L13.3707 6.71789Z"
      fill="url(#paint3_linear_214_8172)"
    />
    <path
      opacity="0.2"
      d="M13.2981 13.2098L1.9849 19.6182C1.76076 19.7883 1.48719 19.8811 1.20549 19.8826C0.923796 19.8841 0.649252 19.7942 0.4233 19.6265L0.365234 19.6844L0.42291 19.7404L0.4233 19.74C0.649214 19.9078 0.923773 19.9978 1.2055 19.9963C1.48722 19.9948 1.7608 19.9019 1.9849 19.7317L13.3707 13.2821L13.2981 13.2098Z"
      fill="black"
    />
    <path
      opacity="0.12"
      d="M0.36352 19.5708C0.107969 19.2661 -0.0210755 18.8756 0.00281476 18.4791V18.5926C-0.0210898 18.9891 0.107957 19.3796 0.36352 19.6843L0.421585 19.6264L0.36352 19.5708Z"
      fill="black"
    />
    <path
      opacity="0.12"
      d="M17.1829 11.0084L13.2969 13.2098L13.3694 13.2821L17.1829 11.1219C17.4072 11.0249 17.6013 10.8696 17.745 10.6724C17.8887 10.4751 17.9767 10.2431 17.9999 10.0004C17.9517 10.2201 17.8528 10.4257 17.7109 10.6007C17.5691 10.7757 17.3883 10.9153 17.1829 11.0084V11.0084Z"
      fill="black"
    />
    <path
      opacity="0.25"
      d="M1.98428 0.382284L17.1835 8.99167C17.389 9.08479 17.5698 9.22444 17.7117 9.3995C17.8535 9.57456 17.9524 9.78021 18.0005 10.0001C17.9775 9.75729 17.8895 9.52512 17.7458 9.32777C17.6021 9.13043 17.4079 8.97515 17.1835 8.87814L1.98428 0.268761C0.894992 -0.34827 0.00390625 0.164252 0.00390625 1.40811V1.52163C0.00390625 0.277852 0.894992 -0.23467 1.98428 0.382284Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_214_8172"
        x1="9.22088"
        y1="1.2265"
        x2="-3.81933"
        y2="14.3066"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A0FF" />
        <stop offset="0.00657" stopColor="#00A1FF" />
        <stop offset="0.2601" stopColor="#00BEFF" />
        <stop offset="0.5122" stopColor="#00D2FF" />
        <stop offset="0.7604" stopColor="#00DFFF" />
        <stop offset="1" stopColor="#00E3FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_214_8172"
        x1="18.6016"
        y1="10.0004"
        x2="-0.257408"
        y2="10.0004"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE000" />
        <stop offset="0.4087" stopColor="#FFBD00" />
        <stop offset="0.7754" stopColor="#FFA500" />
        <stop offset="1" stopColor="#FF9C00" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_214_8172"
        x1="11.582"
        y1="11.7833"
        x2="-6.10158"
        y2="29.521"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3A44" />
        <stop offset="1" stopColor="#C31162" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_214_8172"
        x1="-2.08057"
        y1="-5.40426"
        x2="5.81587"
        y2="2.51635"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32A071" />
        <stop offset="0.0685" stopColor="#2DA771" />
        <stop offset="0.4762" stopColor="#15CF74" />
        <stop offset="0.8009" stopColor="#06E775" />
        <stop offset="1" stopColor="#00F076" />
      </linearGradient>
    </defs>
  </svg>
);
export const JustLogo = () => (
  <svg
    width="20"
    height="28"
    viewBox="0 0 20 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.51083 27.6892C14.5801 27.6892 18.7046 23.6415 18.7046 18.6666V9.3363H0.316406V18.6666C0.316406 23.6415 4.44084 27.6892 9.51083 27.6892Z"
      fill="white"
    />
    <path
      d="M0 0V18.6665C0 23.8208 4.25828 28 9.51138 28C14.7645 28 19.0221 23.8208 19.0221 18.6665V0H0ZM18.7051 9.64262V18.6665C18.7051 23.6413 14.5807 27.689 9.51138 27.689C4.44206 27.689 0.316958 23.6413 0.316958 18.6665V9.33614H18.7051V9.64262Z"
      fill="#0A0A0A"
    />
    <path
      d="M15.6149 15.5972C13.8625 15.1384 11.9986 15.3131 10.3684 16.089C10.8382 17.6868 10.7778 19.3881 10.1958 20.9506C9.61373 22.5131 8.53979 23.8569 7.12695 24.7908C8.30736 24.9827 9.51788 24.8957 10.6565 24.5372C11.7952 24.1788 12.8286 23.5593 13.6699 22.731C14.6049 21.81 15.2764 20.6672 15.6175 19.4158C15.9587 18.1645 15.9578 16.8481 15.6149 15.5972Z"
      fill="#2FA155"
    />
    <path
      d="M9.44508 16.6177C9.01618 14.8993 7.92727 13.4059 6.40203 12.4443C5.41774 13.0604 4.60565 13.9042 4.03833 14.9003C3.47102 15.8964 3.16616 17.0137 3.15099 18.1525C3.13582 19.2912 3.41081 20.4159 3.9514 21.4259C4.49199 22.436 5.28133 23.3 6.24888 23.9407C6.30081 23.975 6.34676 24.0152 6.3987 24.0483C7.64117 23.2752 8.6033 22.1425 9.14976 20.8096C9.69622 19.4767 9.7995 18.0107 9.44508 16.6177Z"
      fill="#2FA155"
    />
    <path
      d="M3.65211 6.36982C3.64634 6.70588 3.47032 6.87434 3.12407 6.87521H3.05748C2.70989 6.87521 2.53233 6.70653 2.52478 6.36917V5.46206H1.58789V6.4372C1.59366 6.83849 1.7204 7.15836 1.96811 7.3968C2.2056 7.62319 2.52088 7.75592 2.85306 7.76936V7.77519H3.32516V7.76936C3.65886 7.75741 3.97611 7.62533 4.21544 7.39874C4.46226 7.15987 4.589 6.84 4.59566 6.43915V1.55823H3.65211V6.36982Z"
      fill="white"
    />
    <path
      d="M13.2967 4.91249L12.5256 4.85353C12.2983 4.8289 12.1398 4.77297 12.0502 4.68571C12.0052 4.65432 11.969 4.61253 11.9448 4.56411C11.9206 4.51569 11.9092 4.46218 11.9116 4.40839C11.9094 4.33137 11.9248 4.25483 11.9565 4.18426C11.9883 4.11369 12.0357 4.05083 12.0954 4.00019C12.212 3.89458 12.417 3.84145 12.7094 3.84145C12.9421 3.83716 13.1735 3.87608 13.3912 3.95613C13.5936 4.042 13.7849 4.15074 13.9612 4.2801L14.5392 3.59588C14.3127 3.39751 14.0533 3.23782 13.7721 3.12353C13.6168 3.0666 13.4566 3.02323 13.2933 2.99394C13.1021 2.96521 12.9088 2.95156 12.7154 2.95312C12.2199 2.95312 11.8109 3.07407 11.4882 3.31597C11.157 3.55743 10.9885 3.92006 10.9827 4.40386C10.9885 4.80601 11.117 5.11897 11.3683 5.34272C11.6196 5.56648 11.9378 5.6993 12.3232 5.7412L13.1449 5.8196C13.292 5.81858 13.4364 5.85787 13.5617 5.93299C13.6633 5.99994 13.7142 6.11787 13.7142 6.28676C13.7164 6.37636 13.6939 6.4649 13.6489 6.5431C13.6039 6.62131 13.5382 6.68629 13.4585 6.73125C13.2586 6.84444 13.0296 6.89953 12.7986 6.89C12.1975 6.89 11.707 6.68892 11.327 6.28676L10.6758 6.9347C11.2817 7.49625 11.9636 7.77702 12.7214 7.77702C13.2802 7.77054 13.7384 7.64096 14.0957 7.38826C14.4531 7.13556 14.6366 6.78309 14.6464 6.33083C14.6464 5.94206 14.5463 5.61982 14.3461 5.3641C14.1312 5.10104 13.7814 4.9505 13.2967 4.91249Z"
      fill="white"
    />
    <path
      d="M17.4356 3.68865V2.95389H16.7484V1.55823H15.8042V2.95389H15.3301V3.68865H15.8042V6.60113C15.8042 6.97693 15.9127 7.26419 16.1298 7.46289C16.2362 7.56662 16.3634 7.6479 16.5035 7.70164C16.6436 7.75537 16.7936 7.78041 16.9442 7.77519H17.4356V6.87585H17.0827C16.8523 6.88147 16.7413 6.76355 16.7497 6.52208V3.68865H17.4356Z"
      fill="white"
    />
    <path
      d="M8.723 5.79531C8.723 6.11712 8.63421 6.36852 8.45665 6.54951C8.36872 6.63553 8.26418 6.7038 8.14905 6.75038C8.03392 6.79696 7.91046 6.82094 7.78578 6.82094C7.66109 6.82094 7.53763 6.79696 7.4225 6.75038C7.30737 6.7038 7.20283 6.63553 7.1149 6.54951C6.93734 6.36895 6.84855 6.11755 6.84855 5.79531V2.9541H5.90234V6.129C5.90811 6.66679 6.08169 7.07304 6.42306 7.34777C6.70582 7.61627 7.08281 7.77028 7.47781 7.77865H8.09175C8.48674 7.77028 8.86373 7.61627 9.14649 7.34777C9.48831 7.07304 9.66188 6.66679 9.66721 6.129V2.9541H8.723V5.79531Z"
      fill="white"
    />
  </svg>
);
export const SendMessageImage = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 18H7V6H17C17 6.55 17.45 7 18 7C18.55 7 19 6.55 19 6V3C19 1.9 18.1 1 17 1L7 1.01C5.9 1.01 5 1.9 5 3V21C5 22.1 5.9 23 7 23H17C18.1 23 19 22.1 19 21V18C19 17.45 18.55 17 18 17C17.45 17 17 17.45 17 18Z"
      fill="white"
    />
    <path
      d="M21.65 11.65L18.86 8.86003C18.54 8.54003 18 8.76003 18 9.21003V11H14C13.45 11 13 11.45 13 12C13 12.55 13.45 13 14 13H18V14.79C18 15.24 18.54 15.46 18.85 15.14L21.64 12.35C21.84 12.16 21.84 11.84 21.65 11.65Z"
      fill="white"
    />
  </svg>
);
export const FeaturesSourced = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill="#008060" />
    <path
      d="M50.3327 32L46.266 27.35L46.8327 21.2L40.816 19.8333L37.666 14.5L31.9993 16.9333L26.3327 14.5L23.1827 19.8167L17.166 21.1667L17.7327 27.3333L13.666 32L17.7327 36.65L17.166 42.8167L23.1827 44.1833L26.3327 49.5L31.9993 47.05L37.666 49.4833L40.816 44.1667L46.8327 42.8L46.266 36.65L50.3327 32ZM27.6327 38.6833L23.666 34.6833C23.016 34.0333 23.016 32.9833 23.666 32.3333L23.7827 32.2167C24.4327 31.5667 25.4993 31.5667 26.1493 32.2167L28.8327 34.9167L37.416 26.3167C38.066 25.6667 39.1327 25.6667 39.7827 26.3167L39.8993 26.4333C40.5493 27.0833 40.5493 28.1333 39.8993 28.7833L30.0327 38.6833C29.3493 39.3333 28.2993 39.3333 27.6327 38.6833Z"
      fill="white"
    />
  </svg>
);
export const FeaturesSorted = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill="#008060" />
    <path
      d="M17 33.6667H20.3333V30.3333H17V33.6667ZM17 40.3333H20.3333V37H17V40.3333ZM20.3333 47V43.6667H17C17 45.5 18.4833 47 20.3333 47ZM17 27H20.3333V23.6667H17V27ZM37 47H40.3333V43.6667H37V47ZM43.6667 17H27C25.15 17 23.6667 18.5 23.6667 20.3333V37C23.6667 38.8333 25.15 40.3333 27 40.3333H43.6667C45.5 40.3333 47 38.8333 47 37V20.3333C47 18.5 45.5 17 43.6667 17ZM42 37H28.6667C27.75 37 27 36.25 27 35.3333V22C27 21.0833 27.75 20.3333 28.6667 20.3333H42C42.9167 20.3333 43.6667 21.0833 43.6667 22V35.3333C43.6667 36.25 42.9167 37 42 37ZM30.3333 47H33.6667V43.6667H30.3333V47ZM23.6667 47H27V43.6667H23.6667V47Z"
      fill="white"
    />
  </svg>
);
export const FeaturesPacked = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill="#008060" />
    <path
      d="M45.334 15.3333H18.6673C17.0007 15.3333 15.334 16.8333 15.334 18.6666V23.6833C15.334 24.8833 16.0507 25.9166 17.0007 26.4999V45.3333C17.0007 47.1666 18.834 48.6666 20.334 48.6666H43.6673C45.1673 48.6666 47.0007 47.1666 47.0007 45.3333V26.4999C47.9507 25.9166 48.6673 24.8833 48.6673 23.6833V18.6666C48.6673 16.8333 47.0007 15.3333 45.334 15.3333ZM35.334 35.3333H28.6673C27.7507 35.3333 27.0007 34.5833 27.0007 33.6666C27.0007 32.7499 27.7507 31.9999 28.6673 31.9999H35.334C36.2507 31.9999 37.0007 32.7499 37.0007 33.6666C37.0007 34.5833 36.2507 35.3333 35.334 35.3333ZM45.334 23.6666H18.6673V18.6666H45.334V23.6666Z"
      fill="white"
    />
  </svg>
);
export const FeaturesDelivered = () => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fill="#008060" />
    <path
      d="M43.6673 23.6666C43.6673 21.8333 42.1673 20.3333 40.334 20.3333H37.0007C36.084 20.3333 35.334 21.0833 35.334 21.9999C35.334 22.9166 36.084 23.6666 37.0007 23.6666H40.334V28.0833L34.534 35.3333H28.6673V28.6666C28.6673 27.7499 27.9173 26.9999 27.0007 26.9999H22.0007C18.3173 26.9999 15.334 29.9833 15.334 33.6666V36.9999C15.334 37.9166 16.084 38.6666 17.0007 38.6666H18.6673C18.6673 41.4333 20.9007 43.6666 23.6673 43.6666C26.434 43.6666 28.6673 41.4333 28.6673 38.6666H34.534C35.5507 38.6666 36.5007 38.1999 37.134 37.4166L42.934 30.1666C43.4173 29.5666 43.6673 28.8333 43.6673 28.0833V23.6666ZM23.6673 40.3333C22.7507 40.3333 22.0007 39.5833 22.0007 38.6666H25.334C25.334 39.5833 24.584 40.3333 23.6673 40.3333Z"
      fill="white"
    />
    <path
      d="M22.0007 22H27.0007C27.9173 22 28.6673 22.75 28.6673 23.6667C28.6673 24.5833 27.9173 25.3333 27.0007 25.3333H22.0007C21.084 25.3333 20.334 24.5833 20.334 23.6667C20.334 22.75 21.084 22 22.0007 22Z"
      fill="white"
    />
    <path
      d="M43.668 33.6665C40.9013 33.6665 38.668 35.8998 38.668 38.6665C38.668 41.4332 40.9013 43.6665 43.668 43.6665C46.4346 43.6665 48.668 41.4332 48.668 38.6665C48.668 35.8998 46.4346 33.6665 43.668 33.6665ZM43.668 40.3332C42.7513 40.3332 42.0013 39.5832 42.0013 38.6665C42.0013 37.7498 42.7513 36.9998 43.668 36.9998C44.5846 36.9998 45.3346 37.7498 45.3346 38.6665C45.3346 39.5832 44.5846 40.3332 43.668 40.3332Z"
      fill="white"
    />
  </svg>
);
export const RightArrow = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2931 6.70711C14.9026 6.31658 14.9026 5.68342 15.2931 5.29289C15.6837 4.90237 16.3168 4.90237 16.7074 5.29289L22.7073 11.2929C23.0979 11.6834 23.0979 12.3166 22.7073 12.7071L16.7074 18.7071C16.3168 19.0976 15.6837 19.0976 15.2931 18.7071C14.9026 18.3166 14.9026 17.6834 15.2931 17.2929L19.586 13H2.01103C1.45265 13 1 12.5523 1 12C1 11.4477 1.45265 11 2.01103 11H19.586L15.2931 6.70711Z"
      fill="#008060"
    />
  </svg>
);
export const AppStoreLogo = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2890_4392)">
      <path
        d="M17.556 0H4.444C1.98825 0 0 1.98825 0 4.444V17.5588C0 20.0117 1.98825 22 4.444 22H17.5588C20.0117 22 22.0027 20.0118 22.0027 17.556V4.444C22 1.98825 20.0118 0 17.556 0Z"
        fill="url(#paint0_linear_2890_4392)"
      />
      <path
        d="M10.9066 5.05444L11.3521 4.28444C11.6271 3.80319 12.2404 3.64094 12.7216 3.91594C13.2029 4.19094 13.3651 4.80419 13.0901 5.28544L8.79739 12.7159H11.9021C12.9086 12.7159 13.4724 13.8984 13.0351 14.7179H3.93264C3.37714 14.7179 2.93164 14.2724 2.93164 13.7169C2.93164 13.1614 3.37714 12.7159 3.93264 12.7159H6.48464L9.75164 7.05369L8.73139 5.28269C8.45639 4.80144 8.61864 4.19369 9.09989 3.91319C9.58114 3.63819 10.1889 3.80044 10.4694 4.28169L10.9066 5.05444ZM7.04564 15.7492L6.08314 17.4184C5.80814 17.8997 5.19489 18.0619 4.71364 17.7869C4.23239 17.5119 4.07014 16.8987 4.34514 16.4174L5.06014 15.1799C5.86864 14.9297 6.52589 15.1222 7.04564 15.7492ZM15.3341 12.7214H17.9384C18.4939 12.7214 18.9394 13.1669 18.9394 13.7224C18.9394 14.2779 18.4939 14.7234 17.9384 14.7234H16.4919L17.4681 16.4174C17.7431 16.8987 17.5809 17.5064 17.0996 17.7869C16.6184 18.0619 16.0106 17.8997 15.7301 17.4184C14.0856 14.5667 12.8509 12.4327 12.0314 11.0109C11.1926 9.56444 11.7921 8.11244 12.3834 7.62019C13.0406 8.74769 14.0224 10.4499 15.3341 12.7214Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_2890_4392" x1="11.0014" y1="0" x2="11.0014" y2="22" gradientUnits="userSpaceOnUse">
        <stop stop-color="#18BFFB" />
        <stop offset="1" stop-color="#2072F3" />
      </linearGradient>
      <clipPath id="clip0_2890_4392">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
