import axios from "axios";

const API_ROOT = process.env.REACT_APP_API_BASE_URL
const responseBody = (res:any) => res.data;

const requests = {
    get: ({url}:any) =>
        axios
            .get(`${API_ROOT}${url}`)
            .then(responseBody),

    post: ({url, body}:any) =>
        axios
            .post(`${API_ROOT}${url}`, body)
            .then(responseBody),

};

const SMS = {
    send: (data:any) => requests.post({url:'/send/sms', body:data}),
};


const Services = {
    SMS,
}
export default Services
