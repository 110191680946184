const PlayStoreLink =
  'https://play.google.com/store/apps/details?id=com.just.direct';

export const Heading = 'TERMS AND CONDITIONS';
export const TermsConditionsB2c = [
  {
    title: 'INTRODUCTION AND ACKNOWLEDGMENT',
    points: [
      {
        txt: (
          <div>
            Krishiacharya Technologies India Private Limited (the “Company”)
            maintains this website/application (
            <a
              href={process.env.REACT_APP_JUST_WEBSITE_URL}
              target='_blank'
              rel='noreferrer'
            >
              {process.env.REACT_APP_JUST_WEBSITE_URL}
            </a>)
            and mobile app – <b> Just Fresh</b> on Google Android Playstore (&nbsp;
            <a href={PlayStoreLink} target='_blank' rel='noreferrer'>
              {PlayStoreLink}
            </a>
            &nbsp;)
            and IOS App Store (&nbsp;
            <a href='https://apps.apple.com/in/app/just-fresh-fruit-vegetable/id1634024358' target='_blank' rel='noreferrer'>
              https://apps.apple.com/in/app/just-fresh-fruit-vegetable/id1634024358
            </a>
            &nbsp;)
            mobile-optimized versions of the Website/application, digital
            applications, and other media formats for use and promotion of the
            Company (collectively and individually, the  <b>"Website/application"</b>)
            which acts as an “intermediary” in accordance with the meaning of
            “Intermediary” envisaged under Section 2(w) of the Information
            Technology Act, 2000, as amended from time to time (“Intermediary”).
            The Website/application is an online listing and discovery platform to
            facilitate transactions by providing customers fresh fruits and vegetables. Access and use of this Website/application by you are governed
            exclusively by these terms and conditions{' '}
            <b>("Terms and Conditions")</b>.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The Terms and Conditions are between you and the Company. User
            understands and accepts that the Company maintains the Website to
            provide visitors with information about the Company, its Services
            (defined hereinbelow) and products. You also accept that you are
            required to read the below mentioned Terms and Conditions, and any
            use of the Website constitutes your acceptance and agreement to be
            bound by such terms, and the changes made to this Terms and
            Conditions from time to time, relating to your usage of the Website
            as communicated and made available on the Company’s Website
            <ol type='i'>
              <li>
                <b> Legal Name of the Entity: </b>Krishiacharya Technologies
                Private Limited
              </li>
              <li>
                <b> Registered Address: </b>06th Floor, Delta Tower, Plot No 54, Sector 44, Institutional Area, Gurgaon, Haryana, India, 122002
              </li>
              <li>
                <b>Name & Details of its Website/application:</b>&nbsp;{' '}
                <a
                  href={process.env.REACT_APP_JUST_WEBSITE_URL}
                  target='_blank'
                  rel='noreferrer'
                >
                  www.buyjustfresh.com
                </a>
              </li>
            </ol>
          </div>
        ),
      },
    ],
  },
  {
    title: 'DEFINITIONS AND INTERPRETATION',
    points: [
      {
        txt: (
          <div>
            Unless the context otherwise requires, for these Terms and Conditions, the following terms shall have the meaning ascribed to them hereunder:
          </div>
        ),
        subPoints: [
          <div>
            <b>“Applicable Law”</b> means all laws, ordinances, statutes, rules, orders, decrees, injunctions, licenses, permits, approvals, authorizations, consents, waivers, privileges, agreements, and regulations of any governmental authority/court of law having jurisdiction over the relevant matter including any interpretations thereof, in effect;
          </div>,
          <div>
            <b>“Intellectual Property Rights”</b> means all patents, designs and drawings, trademarks, service marks, logos, domain names, and utility models, copyrights, inventions, brand names, and business names, and any similar rights and the benefit (subject to the burden) of any of the foregoing (in each case whether registered or otherwise, and includes applications for the grant of any of the foregoing and the right to apply for any of the foregoing in any part of the world);
          </div>,
          <div>
            <b>“Privacy Policy”</b>
            shall mean the Privacy Policy available on the Website/application and shall form the exclusive part of Terms and Conditions;
          </div>,
          <div>
            <b>"Services"</b> means and includes selling of Fruits and Vegetables and other consumables as listed from time to time in India through application or website, 
          </div>,
          <div>
            <b>"User(s) / you"</b> means and includes selling of Fruits and Vegetables and other consumables as listed from time to time in India through application or website, 
          </div>,
          <div>
            <b>"User Account"</b> means the personal online account created by the User to gain access and use the Website/application.
          </div>,
        ],
      },
      {
        txt: (
          <div>
            Accordingly, the terms <b>“Krishiacharya”</b>, <b>"JUST"</b>,
            <b>“website/application”</b>, <b>“KTPL”</b>, <b>“we”</b>, <b>“our”</b> and <b>“us”</b> in these
            Terms and Conditions refer to the Company.
          </div>
        ),
      },
    ],
  },
  {
    title: 'PROPRIETARY RIGHTS',
    points: [
      {
        txt: (
          <div>
            User acknowledges and agrees that the Company owns all legal right, title, and interest in and to the Services, including any Intellectual Property Rights which subsist in the Services (whether those rights happen to be registered or not, and wherever in the world those rights may exist). User further acknowledges that the Services may contain information which is designated confidential by the Company and that User shall not disclose such information without the Company's prior written consent.
          </div>
        ),
      },
      {
        txt: (
          <div>
            Unless the User has agreed otherwise in writing with the Company, nothing in these Terms and Conditions gives the User a right to use any of the Company's trade names, trademarks, service marks, logos, domain names, and other distinctive brand features.
          </div>
        ),
      },
      {
        txt: (
          <div>
            Unless you have been expressly authorized to do so in writing by the Company, User agrees that in using the Services, User will not use any trademark, service mark, trade name, the logo of any company or organization in a way that is likely or intended to confuse the owner or authorized user of such marks, names or logos.
          </div>
        ),
      },
    ],
  },
  {
    title: 'USER ACCOUNTS',
    points: [
      {
        txt: (
          <div>
            To access the Services or the Website/application a User must first register an account with the Website/application <b>(“User Account”)</b> .A User must be at least 18 (eighteen) years of age to be eligible to register an account with us.
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Confidentiality Obligation:</b>   User will be solely responsible for maintaining the confidentiality of their User Account and login details, and we will not be responsible for misuse of your User Account by any third party, whether authorized by you or not.. User is also prohibited from sharing their log-in details or allowing anyone to access their account or do anything that might put their User Account at risk. However, if Company is retaining any data under this or any other policy, it will be under legal obligation to maintain its confidentiality, as per applicable laws, and the Users on the platform provide consent that such data as per applicable laws, can be retained by the Company (if required). However, if required, Company is entitled to provide the data to any authority as may be required under applicable laws or court orders.
          </div>
        ),
      },
      {
        txt: (
          <div>
            Users are responsible for any activity on the Website/application/application arising out of any failure to keep their User Account confidential and may be held liable for any losses arising out of such a failure. User agrees to notify us immediately of any unauthorized use of their User Account or any other breach of security.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The Company reserves the right to refuse to offer access to or use of the Website/application to any person at the Company’s sole discretion including by changing its eligibility criteria at any time.
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>DELETE MY ACCOUNT</b>
            <div>
              <ol>
                <li>
                  You can delete your account from within the App. Deleting your account is an irreversible process, which we can't revert even if you perform it by accident.
                </li>
                <li>
                  To delete your account.
                  <ol>
                    <li>
                      Open JUST Fresh.
                    </li>
                    <li>
                      Tap My Profile icon &gt; Edit Profile.
                    </li>
                    <li>
                      Click on Delete My Account button.
                    </li>
                    <li>
                      Select a reason for why you're deleting your account in the dropdown.
                    </li>
                    <li>
                      Tap PLEASE DELETE MY ACCOUNT.
                    </li>
                  </ol>
                </li>
                <li>
                  Deleting your account will.
                  <ol>
                    <li>
                      Delete your account from App.
                    </li>
                    <li>
                      Erase your order history.
                    </li>
                    <li>
                      Delete your cache files.
                    </li>
                  </ol>
                </li>
                <li>
                  If you delete your account.
                  <ol>
                    <li>
                      You can't regain access to your account.
                    </li>
                    <li>
                      It may take up to 30 days from the beginning of the deletion process to delete your information. Copies of your information may also remain after the 30 days in the backup storage that we use to recover in the event of a disaster, software error, or other data loss event.
                    </li>
                    <li>
                      We may retain certain log data in our databases even after you delete your account, but such data will be disassociated from any identifying information such that it can no longer be linked to your account.
                    </li>
                    <li>
                      We may also keep your information for things like legal issues, terms violations, or harm prevention efforts.
                    </li>
                    <li>
                      Please refer to our Privacy Policy for more information.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        ),
      },
    ],
  },
  {
    title: 'SERVICES PROVIDED ON THE WEBSITE',
    points: [
      {
        txt: (
          <div>
           The Company provides a platform/marketplace to the User through the Website/application where Customers can connect to place orders for Fruits and Vegetables.
          </div>
        ),
      },
    ],
  },
  {
    title: 'PRIVACY POLICY',
    points: [
      {
        txt: (
          <div>
            Please review our Privacy Policy to understand what kind of information we gather from you and the specific measures we take to protect your personal information.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The Privacy Policy available on the website/application/application of the Company and shall be read together with the Terms and Conditions.
          </div>
        ),
      },
    ],
  },
  {
    title: 'DISCLAIMER',
    points: [
      {
        txt: (
          <div>
            User agrees that their use of the website/application shall be at their own risk. To the fullest extent permitted by law, the company and its officers, managers, members, directors, employees, successors, assigns, subsidiaries, affiliates, suppliers, and agents disclaim all warranties, express, implied, statutory or otherwise, and make no warranties, representations, or guarantees in connection with the website/application, the services offered on or through the website/application, any data, materials, content, relating to the quality, suitability, truth, accuracy or completeness of any information or material contained or presented on the website/application, including without limitation the materials, data and submitted content of other users of this site or other third parties. Unless otherwise explicitly stated, to the maximum extent permitted by applicable law, the website/application, the services offered on or through the website/application, data, materials, submitted content, and any information or material contained or presented on the website/application is provided to you on an “as is”, “as available” and “where-is” basis with no warranty, express or implied, of merchantability, fitness for a particular purpose, or non-infringement of third-party rights.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The Company does not provide any warranties against errors, mistakes, or inaccuracies of data, content, information, materials, the substance of the website/application, postings, feedback or content, any unauthorized access to or use of our secure servers, and/or any personal information and/or financial information stored therein, any bugs, viruses, trojan horses, or the like which may be transmitted to or through the website/application, any interruption or cessation of transmission to or from the website/application, any defamatory, offensive, or illegal conduct of any third party or user, or any loss or damage of any kind incurred as a result of the use of any data, content, information, materials, the substance of the website/application or content posted, emailed, transmitted, or otherwise made available via the website/application.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The Company does not endorse, warrant, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the website/application or any hyperlinked site or featured in any banner or other advertisement. The company will not be a party to or in any way be responsible for monitoring any transaction between you and any party, including third-party providers of products or services. As with the use of any product or service, and the publishing or posting of any material through any medium or in any environment, you should use your best judgment and exercise caution where appropriate.
          </div>
        ),
      },
      {
        txt: (
          <div>
            <div>
              When surfing or using the website/application or registering with a mobile number on <b>JUST Fresh App</b>,  the User authorizes and gives consent to the Company to send, either through any third-party service provider, from time to time, various information/alerts/SMS/WhatsApp Messages/Calls or commercial communication, and other services on the aforesaid listed telephone/mobile numbers, whether these numbers are registered with National Do Not Call Registry/listed in National Customer Preference Register or not. User cannot hold the Company or its third-party service provider liable/institute complaint under the Telecom Commercial Communications Customer Preference (TRAI) Regulations, 2010 or such other applicable regulations including amendment thereof, as may be applicable from time to time.
            </div>
            <div>
              User can stop this service, by writing an email to&nbsp;
              <a href='mailto:service@gojust.life'>service@gojust.life</a> or
              calling customer number&nbsp;
              <a href='tel:+91-8068764589'>+91-8068764589</a>.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            User agrees that this agreement or any other related policy may be updated from time to time and shall be binding on the users.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The Company do not -
          </div>
        ),
        subPoints: [
          <div>
            Adopt any unfair trade practice either on platform or otherwise.
          </div>,
          <div>
            Falsely represent itself as a consumer and post reviews about goods or services or misrepresent the quality or the features of any goods and services.
          </div>,
          <div>
            Refuse to take back products or withdraw or refuse to refund consideration.
          </div>,
          <div>
            Discriminate between or classify the Users.
          </div>,
        ],
      },
    ],
  },
  {
    title: 'UPDATION/ AMENDMENT',
    points: [
      {
        txt: (
          <div>
            The Company can update this or any other related policy by uploading an updated version of this policy on the Website/application or any click-through format which would be considered a deemed consent of user.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The most recent version of this or any other policy will govern the use of the information (including Personal Information) on the Platform. We may revise or amend this from time to time. Company may also, but is not required to, notify you of changes to the policies via email to the email address associated with your account. If you object to any changes in this or any other related policy, you should immediately stop using the platform and close any related accounts. By continuing to access or use the platform after changes have become effective, you agree to be bound by the revised amendments.
          </div>
        ),
      },
    ],
  },
  {
    title: 'LIMITATIONS OF LIABILITY',
    points: [
      {
        txt: (
          <div>
            In no event shall the Company, its affiliates or its respective officers, managers, members, directors, employees, successors, assigns, subsidiaries, suppliers, attorneys or agents, be liable to a user for any direct, indirect, incidental, special, punitive, consequential or exemplary damages (including but not limited to loss of business, revenue, profits, use, data or other economic advantage) whatsoever resulting from any (i) access to or use of the Website/application;; (ii) any unauthorized access to or use of our secure servers and / or any and all personal information and / or financial information stored therein; (iii) any bugs, viruses, trojan horses, or the like which may be transmitted to or through the website/application by any third party; (iv) any interruption or cessation of transmission to or from the website/application; (v) any errors or omissions in any data, content, information, materials or substance of the website/application or content;, or any other dispute that arises between users of the website/application; (vi) any defamatory, offensive, or illegal conduct of any third party or user;
          </div>
        ),
      },
      {
        txt: (
          <div>
            The foregoing limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction.
          </div>
        ),
      },
      {
        txt: (
          <div>
            In no event shall the total aggregate liability of the company, or any of the above-referenced respective parties, arising from or relating to the website/application, exceed the transaction amount to the Company by a User under which such liability arose.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The Website/application may contain links to third-party website/applications that are not owned or controlled by the Company. The Company does not have any control over and assumes no responsibility for, the content, privacy policies, terms of use, or practices of any third-party website/applications. In addition, the Company will not and cannot censor or edit the content of any third-party site. By using third-party website/applications, you expressly relieve the company from any liability arising from your use of any third-party website/application. Accordingly, please be advised to read the terms and conditions and privacy policy of each third-party website/application that you visit, including those directed by the links contained on the website/application.
          </div>
        ),
      },
    ],
  },
  {
    title: 'INDEMNIFICATION AND RELEASE',
    points: [
      {
        txt: (
          <div>
            You agree to defend, indemnify and hold harmless the Company, its affiliates, and its respective officers, managers, members, directors, employees, successors, assigns, subsidiaries, affiliates, suppliers, and agents, from and against any claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorneys' fees) arising from your use of, access to, and participation on the Website/application; your violation of any provision of the Terms and Conditions, including the privacy policy; your violation of any third-party right, including without limitation any copyright, property, proprietary, Intellectual Property Rights, or breach of your express or implied representations and warranties. This defence and indemnification obligation will survive these terms and conditions and your use of the Website/application.
          </div>
        ),
      },
      {
        txt: (
          <div>
            JUST Fresh a marketplace where fruits and vegetables displayed on Website/application for the customers to make choice to buy from the options displayed on website/application, the customers shall on their prudence make choice to purchase fruits and vegetables. The Company shall not be held accountable for any reasons whatsoever.
          </div>
        ),
      },
    ],
  },
  {
    title: 'BREACH OF TERMS AND CONDITIONS',
    points: [
      {
        txt: (
          <div>
            Without prejudice to the Company’s other rights under the Terms and Conditions, if a User breaches any provisions of these Terms and Conditions in any way, or if the Company reasonably suspects that a User has breached these Terms and Conditions in any way, the Company may take such action as it deems appropriate to deal with the breach, including (a) temporarily suspend such User’s access to the Website/application, (b) permanently prohibit such User from accessing the Website/application, (c) blocking computers/devices using such User’s IP address from accessing the Website/application, (d) contacting any or all of such User’s internet service providers and request that they block such User’s access to the Website/application, (e) commence legal action against such User, whether for breach of contract or otherwise; and/or (f) suspend or delete such User’s Account.
          </div>
        ),
      },
    ],
  },
  {
    title: 'MODIFICATIONS TO OR TERMINATION OF THE WEBSITE/APPLICATION',
    points: [
      {
        txt: (
          <div>
            <b>Modification or Cessation of Website/application</b>
            <div>
              The Company reserves the right to, at any time and from time to time, modify or discontinue, temporarily or permanently, the Website/application (or any part thereof) with or without notice and in its sole discretion. Users agree that the Company shall not be liable to the User or any third party for any modification, suspension, or discontinuance of the services offered by the Company.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Termination by The Company</b>
            <div>
              User hereby acknowledges and agree that the Company, in its sole and absolute discretion, has the right (but not the obligation) to delete, terminate, or deactivate User Account, block User’s email or IP address, cancel the Website/application or otherwise terminate User’s access to or participation in the use of the Website/application (or any part thereof), or remove and discard any content submitted by User on the Website/application, immediately and without notice, for any reason, including without limitation, User Account inactivity or if the Company believes or has reason to believe that the User has violated any provision of the Terms and Conditions.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Effect of Termination</b>
            <div>
              Upon termination of the User Account, User’s right to participate in the Website/application, including, but not limited to, (i) adhered to the Terms and Conditions, (ii) maintained continuous activation of User Account, and (iii) participated on the Website/application. In the event of termination, the User Account will be disabled and the User may not be granted access to the User Account or any files or other data contained in the User Account. Notwithstanding the foregoing, residual data may remain in the Company’s system.
              <p className="mt-2">
                Upon termination of Service, the User’s access to the Website/application shall be immediately revoked. The provisions of these Terms and Conditions which by their very nature are intended to survive termination shall survive expiration or termination of the Website/application or User Account.
              </p>
            </div>
          </div>
        ),
      },
    ],
  },
  {
    title: 'REFUND POLICY',
    points: [
      {
        txt: (
          <div>
            <div>
              Refund queries will be catered in case of frauds and disputes
              only. A user has to inform about any such activity by dropping an
              email to <a href='mailto:service@gojust.life'>service@gojust.life</a> or raising concern on the app helpline/chat 
              within 48 hours of learning/ascertaining about the same. The
              Dedicated team will then contact the user within 48 hours to
              understand and analyse the incident. The refunds will be issued
              after complete verification and due diligence by our team. In case
              the concern is not addressed within 48 hours, the user can reach
              out to the Grievances Officer on
              <a href='mailto:grievanceofficer@bijak.in'>
                grievanceofficer@bijak.in.
              </a>{' '}
              The refund will be transferred into the same account from which
              the money was debited subject to successful completion of the
              verification and due diligence process by our dedicated team.
            </div>
          </div>
        ),
      },
    ],
  },
  {
    title: 'MISCELLANEOUS',
    points: [
      {
        txt: (
          <div>
            <b>Governing Law and Jurisdiction</b>
            <div>
              The Terms and Conditions shall be governed in all respects by the laws of India and any legal proceeding arising out of this Agreement will occur exclusively in the courts located in [Haryana], India.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Advertisements</b>
            <div>
            The Company may display advertisements and promotions on the Website. The manner, mode, and extent of advertising by the Company on the Website are subject to change. The Company may from time to time extend offers to the Users on website and application. However, the Company reserves the right to decide or alter the eligibility criteria, terms and conditions of such offer.  The User agrees that the Company shall not be responsible or liable for any loss or damage of any sort incurred by the User as a result of any such dealings or as the result of the presence of such advertisers on the Website.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Use of Cookies</b>
            <div>
              <ol>
                <li>
                  The Company uses cookies to aggregate information about the User’s sessions, track information, store certain User preferences, analyse web page flow, etc. so that the Company can update and redesign the Company Website/application, as necessary, to provide User with the most useful information.
                </li>
                <li>
                  Users will have the option to either accept or decline the use of cookies on their computer/device, whether they are registered or not. However, a User’s experience of the Website/application shall be limited and certain features of the Website/application will not be available if the User declines the use of Cookies.
                </li>
              </ol>
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Assignment</b>
            <div>
              The Terms and Conditions, and any rights and licenses granted
              hereunder, may not be transferred or assigned by the User but may
              be assigned by the Company without restriction.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>No Agency or Partnership</b>
            <div>
              No agency, partnership, joint venture, or employment is created as a result of the Terms and Conditions or User’s use of any part of the Website/application, including without limitation, the contract between the User and The Company. The user does not have any authority whatsoever to bind the Company in any respect. Neither the Company nor any User of the Website/application may direct or control the day-to-day activities of the other or create or assume any obligation on behalf of the other.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Force Majeure</b>
            <div>
              Neither the Company nor the User shall be liable to the other for any delay or failure in performance under the Terms and Conditions, other than payment obligations, arising out of a cause beyond its control and without its fault or negligence. Such causes may include but are not limited to fires, floods, earthquakes, strikes, unavailability of necessary utilities, blackouts, acts of God, acts of declared or undeclared war, acts of regulatory agencies, or national disasters.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>No Third-party Beneficiaries</b>
            <div>
              User agrees that, except as otherwise expressly provided in the Terms and Conditions, there shall be no third-party beneficiaries to the Terms and Conditions.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Notice</b>
            <div>
              User agrees that The Company may provide the User with notices, including those regarding changes to the Terms and Conditions, by email, regular mail, or postings on the Website/application.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Entire Agreement</b>
            <div>
              The Terms and Conditions, together with the Privacy Policy, and any other legal notices or any additional policies published by the Company on the Website/application, shall constitute the entire agreement between User and the Company concerning the Website/application. Please note, however, that other aspects of your use of Services may be governed by additional agreements.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Severability</b>
            <div>
              If any provision of the Terms and Conditions is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of the Terms and Conditions, which shall remain in full force and effect.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>No Waiver</b>
            <div>
              No waiver of any provision of these Terms and Conditions shall be deemed a further or continuing waiver of such term or any other term, and The Company’s failure to assert any right or provision under these Terms and Conditions shall not constitute a waiver of such right or provision.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Compliant & Grievance Mechanism</b> <br />
            To Address the queries/concerns of the users we have defined
            Complaint & Grievance Mechanism.
            <ol type='i'>
              <li>
                <div>
                  <b>Level 1:</b> Users can reach us through the “Help via Call”
                  feature on mob application or can write to us at&nbsp;
                  <a href='mailto:service@gojust.life'>service@gojust.life.</a>
                </div>

              </li>
              <li>
                <div>
                  <b> Level 2:</b> If the user query/concerns are not addressed
                  to the expected levels, then they can reach the Grievance
                  Officer- Mr. Jitender Bedwal. Email id:{' '}
                  <a href='mailto:grievanceofficer@bijak.in'>
                    {' '}
                    grievanceofficer@bijak.in;
                  </a>
                  &nbsp; Grievance officer acknowledges the receipt of any user
                  query/concerns within 48 hours and redresses the complaint
                  within 1 month from the date of receipt of the complaint.
                </div>
              </li>
              <li>
                <div>
                  {' '}
                  <b>Level 3:</b> In the unlikely event that your issue remains
                  unresolved to your satisfaction despite escalating to our
                  Grievance Officer, you can reach out to our Nodal Officer Mr.
                  Nukul Upadhye, Director –&nbsp;
                  <a href='mailto:grievanceofficer@bijak.in'>
                    grievanceofficer@bijak.in
                  </a>
                  . We will respond within 3 business days from the date of
                  receipt of your email.
                </div>
              </li>
            </ol>
          </div>
        ),
      },
    ],
  },
];

export const TermsConditionsB2b = [
  {
    title: 'INTRODUCTION AND ACKNOWLEDGMENT',
    points: [
      {
        txt: (
          <div>
            Krishiacharya Technologies India Private Limited (the “Company”)
            maintains this website (
            <a
              href={process.env.REACT_APP_JUST_WEBSITE_URL}
              target='_blank'
              rel='noreferrer'
            >
              {process.env.REACT_APP_JUST_WEBSITE_URL}
            </a>)
            and mobile app – <b> Just Fresh</b> on Google Android Playstore (&nbsp;
            <a href='https://play.google.com/store/apps/details?id=com.just.fresh' target='_blank' rel='noreferrer'>
              https://play.google.com/store/apps/details?id=com.just.fresh
            </a>
            &nbsp;) and IOS App Store (&nbsp;
            <a href='https://apps.apple.com/in/app/just-retailer-fruits-vegetable/id1659349506' target='_blank' rel='noreferrer'>
              https://apps.apple.com/in/app/just-retailer-fruits-vegetable/id1659349506
            </a>
            &nbsp;)
            mobile-optimized versions of the Website, digital
            applications, and other media formats for use and promotion of the
            Company (collectively and individually, the  <b>"Website"</b>)
            which acts as an “intermediary” in accordance with the meaning of
            “Intermediary” envisaged under Section 2(w) of the Information
            Technology Act, 2000, as amended from time to time (“Intermediary”).
            The Website is an online listing and discovery platform to
            facilitate business-to-business transactions by providing buyers and
            sellers better prices, increased working capital and optimised
            logistics. Access and use of this Website by you are governed
            exclusively by these terms and conditions{' '}
            <b>("Terms and Conditions")</b>.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The Terms and Conditions are between you and the Company. User
            understands and accepts that the Company maintains the Website to
            provide visitors with information about the Company, its Services
            (defined hereinbelow) and products. You also accept that you are
            required to read the below mentioned Terms and Conditions, and any
            use of the Website constitutes your acceptance and agreement to be
            bound by such terms, and the changes made to this Terms and
            Conditions from time to time, relating to your usage of the Website
            as communicated and made available on the Company’s Website
            <ol type='i'>
              <li>
                <b> Legal Name of the Entity: </b>Krishiacharya Technologies
                Private Limited
              </li>
              <li>
                <b> Registered Address: </b>Plot 54, Delta Tower, 6th Floor, Sector 44, Gurugram, Haryana 122003
              </li>
              <li>
                <b>Name & Details of its Website:</b>&nbsp;{' '}
                <a
                  href={process.env.REACT_APP_JUST_WEBSITE_URL}
                  target='_blank'
                  rel='noreferrer'
                >
                  www.buyjustfresh.com
                </a>
              </li>
            </ol>
          </div>
        ),
      },
    ],
  },
  {
    title: 'DEFINITIONS AND INTERPRETATION',
    points: [
      {
        txt: (
          <div>
            Unless the context otherwise requires, for these Terms and
            Conditions, the following terms shall have the meaning ascribed to
            them hereunder:
          </div>
        ),
        subPoints: [
          <div>
            <b>“Applicable Law”</b> means all laws, ordinances, statutes, rules,
            orders, decrees, injunctions, licenses, permits, approvals,
            authorizations, consents, waivers, privileges, agreements, and
            regulations of any governmental authority/court of law having
            jurisdiction over the relevant matter including any interpretations
            thereof, in effect;
          </div>,
          <div>
            <b>“Intellectual Property Rights”</b> means all patents, designs and
            drawings, trademarks, service marks, logos, domain names, and
            utility models, copyrights, inventions, brand names, and business
            names, and any similar rights and the benefit (subject to the
            burden) of any of the foregoing (in each case whether registered or
            otherwise, and includes applications for the grant of any of the
            foregoing and the right to apply for any of the foregoing in any
            part of the world);
          </div>,
          <div>
            <b>“Privacy Policy”</b>
            shall mean the Privacy Policy available on the Website;
          </div>,
          <div>
            <b>"Services"</b> means and includes disbursal of loans to buyers,
            enabling immediate payment to suppliers, buyer and seller ratings to
            bring accountability, incentivize good behavior and address trust
            deficit, easy and convenient bookkeeping, managing large value
            transactions, access to the largest network of verified buyers and
            sellers with accurate price data and aggregation to reduce cost,
            procure and sell Fruits and Vegetables, eliminate wastage and cater
            to partial truckload requirements.
          </div>,
          <div>
            <b>"Transaction Documents"</b> means the various documents required
            to be executed by a User to obtain the Services of the Company on
            and/or from the Website;
          </div>,
          <div>
            <b>"User(s) / you"</b> means you, and does not include your
            permitted assigns, successors, heirs, and legal representatives,
            unless the context requires otherwise;
          </div>,
          <div>
            <b>"User Account"</b> means the personal online account created by
            the User to gain access and use the Website.
          </div>,
        ],
      },
      {
        txt: (
          <div>
            Accordingly, the terms <b>“Krishiacharya”</b>, <b>"JUST"</b>,
            <b>“website”</b>, <b>“we”</b>, <b>“our”</b> and <b>“us”</b> in these
            Terms and Conditions refer to the Company.
          </div>
        ),
      },
    ],
  },
  {
    title: 'PROPRIETARY RIGHTS',
    points: [
      {
        txt: (
          <div>
            User acknowledges and agrees that the Company owns all legal right,
            title, and interest in and to the Services, including any
            Intellectual Property Rights which subsist in the Services (whether
            those rights happen to be registered or not, and wherever in the
            world those rights may exist). User further acknowledges that the
            Services may contain information which is designated confidential by
            the Company and that User shall not disclose such information
            without the Company's prior written consent.
          </div>
        ),
      },
      {
        txt: (
          <div>
            Unless the User has agreed otherwise in writing with the Company,
            nothing in these Terms and Conditions gives the User a right to use
            any of the Company's trade names, trademarks, service marks, logos,
            domain names, and other distinctive brand features.
          </div>
        ),
      },
      {
        txt: (
          <div>
            Unless you have been expressly authorized to do so in writing by the
            Company, User agrees that in using the Services, User will not use
            any trademark, service mark, trade name, the logo of any company or
            organization in a way that is likely or intended to confuse the
            owner or authorized user of such marks, names or logos.
          </div>
        ),
      },
    ],
  },
  {
    title: 'USER ACCOUNTS',
    points: [
      {
        txt: (
          <div>
            To access the Services or the Website a User must first register an
            account with the Website <b> (“User Account”) </b>.A User must be at
            least 18 (eighteen) years of age to be eligible to register an
            account with us.
          </div>
        ),
      },
      {
        txt: (
          <div>
            By registering an account with us  <b>(“Registered User”)</b>, the
            User represents and warrants that you are at least 18 years old,
            that all information you submit is true, accurate, and complete and
            you shall comply with these Terms and Conditions. User further
            represents and warrants that: (a) all required registration
            information submitted by User is truthful, accurate, and complete;
            and that (b) User will maintain the accuracy of such information.
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Confidentiality Obligation:</b>&nbsp;
            User will be solely responsible for maintaining the confidentiality
            of their User Account and login details, and we will not be
            responsible for misuse of your User Account by any third party,
            whether authorized by you or not. User may never use another User’s
            account without Page 3 permission of such User and us. User is also
            prohibited from sharing their log-in details or allowing anyone to
            access their account or do anything that might put their User
            Account at risk. However, if Company is retaining any data under
            this or any other policy, it will be under legal obligation to
            maintain its confidentiality, as per applicable laws, and the
            Buyers/Sellers on the platform provide consent that such data as per
            applicable laws, can be retained by the Company (if required).
            However, if required, Company is entitled to provide the data to any
            authority as may be required under applicable laws or court orders.
          </div>
        ),
      },
      {
        txt: (
          <div>
            Users are responsible for any activity on the Website arising out of
            any failure to keep their User Account confidential and may be held
            liable for any losses arising out of such a failure. User agrees to
            notify us immediately of any unauthorized use of their User Account
            or any other breach of security.
          </div>
        ),
      },
      {
        txt: (
          <div>
            For a User to be listed as a trader on the Website, the User will be
            required to provide copies of their KYC documents and other business
            registrations to the Company and maintain their registration as a
            trader under the applicable State/ Union Territory specific
            Agricultural Produce Marketing Committee (APMC) laws (“APMC Laws”)
            and / or have relevant license from the local mandis in accordance
            with APMC Laws. For the purposes of listing and maintaining its
            status as a trader on the Website, the User hereby represents to the
            Company that prior to and during the subsistence of its listing on
            the Website, all necessary mandi cess/fees/ charges are paid by the
            User with respect to commodities under the applicable APMC Laws
            and/or that such User is expressly permitted under an express
            exception under the relevant APMC Laws.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The Company reserves the right to refuse to offer access to or use
            of the Website to any person or entity at the Company’s sole
            discretion including by changing its eligibility criteria at any
            time.
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>DELETE MY ACCOUNT</b>
            <div>
              <ol>
                <li>
                  You can delete your account from within the App. Deleting your account is an irreversible process, which we can't revert even if you perform it by accident.
                </li>
                <li>
                  To delete your account.
                  <ol>
                    <li>
                      Open JUST Fresh.
                    </li>
                    <li>
                      Tap My Profile icon &gt; Edit Profile.
                    </li>
                    <li>
                      Click on Delete My Account button.
                    </li>
                    <li>
                      Select a reason for why you're deleting your account in the dropdown.
                    </li>
                    <li>
                      Tap PLEASE DELETE MY ACCOUNT.
                    </li>
                  </ol>
                </li>
                <li>
                  Deleting your account will.
                  <ol>
                    <li>
                      Delete your account from App.
                    </li>
                    <li>
                      Erase your order history.
                    </li>
                    <li>
                      Delete your cache files.
                    </li>
                  </ol>
                </li>
                <li>
                  If you delete your account.
                  <ol>
                    <li>
                      You can't regain access to your account.
                    </li>
                    <li>
                      It may take up to 30 days from the beginning of the deletion process to delete your information. Copies of your information may also remain after the 30 days in the backup storage that we use to recover in the event of a disaster, software error, or other data loss event.
                    </li>
                    <li>
                      We may retain certain log data in our databases even after you delete your account, but such data will be disassociated from any identifying information such that it can no longer be linked to your account.
                    </li>
                    <li>
                      We may also keep your information for things like legal issues, terms violations, or harm prevention efforts.
                    </li>
                    <li>
                      Please refer to our Privacy Policy for more information.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        ),
      },
    ],
  },
  {
    title: 'SERVICES PROVIDED ON THE WEBSITE',
    points: [
      {
        txt: (
          <div>
            The Company provides a platform to the User through the Website
            where buyers and suppliers can connect to place orders for Fruits
            and Vegetables.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The Company provides ratings to the buyers and suppliers listed on
            the Website. It helps to identify more trusted buyers and sellers.
            The ratings also help in the procurement of credit which the users
            can use to make payments facilitating easy trade. These ratings are
            provided while keeping in account different factors like transaction
            history, timely payment and payback history.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The Company also provides a credit facility to commission agents
            which the agents can use to make payments to the suppliers. As the
            Company is an intermediary, therefore, loan/ advances shall never be
            directly provided by the Company.
          </div>
        ),
      },
    ],
  },
  {
    title: 'PRIVACY POLICY',
    points: [
      {
        txt: (
          <div>
            Please review our Privacy Policy to understand what kind of
            information we gather from you and the specific measures we take to
            protect your personal information.
          </div>
        ),
      },
    ],
  },
  {
    title: 'DISCLAIMER',
    points: [
      {
        txt: (
          <div>
            User agrees that their use of the website shall be at their own
            risk. To the fullest extent permitted by law, the company and its
            officers, managers, members, directors, employees, successors,
            assigns, subsidiaries, affiliates, suppliers, and agents disclaim
            all warranties, express, implied, statutory or otherwise, and make
            no warranties, representations, or guarantees in connection with the
            website, the services offered on or through the website, any data,
            materials, content, relating to the quality, suitability, truth,
            accuracy or completeness of any information or material contained or
            presented on the website, including without limitation the
            materials, data and submitted content of other users of this site or
            other third parties. Unless otherwise explicitly stated, to the
            maximum extent permitted by applicable law, the website, the
            services offered on or through the website, data, materials,
            submitted content, and any information or material contained or
            presented on the website is provided to you on an “as is”, “as
            available” and “where-is” basis with no warranty, express or
            implied, of merchantability, fitness for a particular purpose, or
            non-infringement of third-party rights.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The Company does not provide any warranties against errors,
            mistakes, or inaccuracies of data, content, information, materials,
            the substance of the website, postings, feedback or content, any
            unauthorized access to or use of our secure servers, and/or any
            personal information and/or financial information stored therein,
            any bugs, viruses, trojan horses, or the like which may be
            transmitted to or through the website, any interruption or cessation
            of transmission to or from the website, any defamatory, offensive,
            or illegal conduct of any third party or user, or any loss or damage
            of any kind incurred as a result of the use of any data, content,
            information, materials, the substance of the website or content
            posted, emailed, transmitted, or otherwise made available via the
            website.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The Company does not endorse, warrant, guarantee, or assume
            responsibility for any product or service advertised or offered by a
            third party through the website or any hyperlinked site or featured
            in any banner or other advertisement. The company will not be a
            party to or in any way be responsible for monitoring any transaction
            between you and any party, including third-party providers of
            products or services. As with the use of any product or service, and
            the publishing or posting of any material through any medium or in
            any environment, you should use your best judgment and exercise
            caution where appropriate.
          </div>
        ),
      },
      {
        txt: (
          <div>
            <div>
              When registering with a mobile number on <b> JUST Fresh App,</b>&nbsp;
              the User authorizes and gives consent to the Company to send,
              either through any third-party service provider, from time to
              time, various information/alerts/SMS/WhatsApp Messages/Calls or
              commercial communication, and other services on the aforesaid
              listed telephone/mobile numbers, whether these numbers are
              registered with National Do Not Call Registry/listed in National
              Customer Preference Register or not. User cannot hold the Company
              or its third-party service provider liable/institute complaint
              under the Telecom Commercial Communications Customer Preference
              (TRAI) Regulations, 2010 or such other applicable regulations
              including amendment thereof, as may be applicable from time to
              time.
            </div>
            <div>
              User can stop this service, by writing an email to&nbsp;
              <a href='mailto:service@gojust.life'>service@gojust.life</a> or
              calling customer number&nbsp;
              <a href='tel:022-35500402'>022-35500402</a>.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            We do not endorse, warrant, guarantee, validate or assume
            responsibility for the descriptions, images, appearance, nature,
            quality, purpose, general features, and other content of goods or
            services on the platform posted by the seller.
          </div>
        ),
      },
      {
        txt: (
          <div>
            User agrees that this agreement or any other related policy may be
            updated from time to time and shall be binding on the users.{' '}
          </div>
        ),
      },
      {
        txt: (
          <div>
            User acknowledges the obligation to pay the agreed commission to the
            Company in compliance with Section 186(7) of the Companies Act.
          </div>
        ),
      },
      {
        txt: (
          <div>
            Furthermore, this is to disclaim further any working capital
            facilities shall be enabled by the Company by any third-party entity
            permitted under Applicable Laws to provide working capital
            facilities and not directly by the Company.
          </div>
        ),
      },
    ],
  },
  {
    title: 'UPDATION/ AMENDMENT',
    points: [
      {
        txt: (
          <div>
            The Company can update this or any other related policy by uploading
            an updated version of this policy on the Website or any
            click-through format which would be considered a deemed consent of
            user.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The most recent version of this or any other policy will govern the
            use of the information (including Personal Information) on the
            Platform. We may revise or amend this from time to time. If we
            decide to change this or the Privacy Policy or any other related
            policy, we will inform you by posting the revised policies on the
            platform. Company may also, but is not required to, notify you of
            changes to the policies via email to the email address associated
            with your account. If you object to any changes in this or any other
            related policy, you should immediately stop using the platform
            and/or Services and close any related accounts. By continuing to
            access or use the platform and/or Services after changes have become
            effective, you agree to be bound by the revised amendments.
          </div>
        ),
      },
    ],
  },
  {
    title: 'LIMITATIONS OF LIABILITY',
    points: [
      {
        txt: (
          <div>
            In no event shall the Company, its affiliates or its respective
            officers, managers, members, directors, employees, successors,
            assigns, subsidiaries, suppliers, attorneys or agents, be liable to
            a user for any direct, indirect, incidental, special, punitive,
            consequential or exemplary damages (including but not limited to
            loss of business, revenue, profits, use, data or other economic
            advantage) whatsoever resulting from any (i) access to or use of the
            Website; (ii) errors, mistakes, or inaccuracies of data,
            Intellectual Property Rights, content, information, materials or
            substance of the Website; (iii) any unauthorized access to or use of
            our secure servers and / or any and all personal information and /
            or financial information stored therein; (iv) any bugs, viruses,
            trojan horses, or the like which may be transmitted to or through
            the website by any third party; (v) any interruption or cessation of
            transmission to or from the website; (vi) any errors or omissions in
            any data, content, information, materials or substance of the
            website or content; (vii) any failed negotiations for purchase of
            fractional ownership of any listed property on the website, any
            disputes that arise during or after the negotiation for purchase of
            fractional ownership of any listed property on the website, or any
            other dispute that arises between users of the website; (viii) any
            defamatory, offensive, or illegal conduct of any third party or
            user;
          </div>
        ),
      },
      {
        txt: (
          <div>
            The foregoing limitation of liability shall apply to the fullest
            extent permitted by law in the applicable jurisdiction.
          </div>
        ),
      },
      {
        txt: (
          <div>
            In no event shall the total aggregate liability of the company, or
            any of the above-referenced respective parties, arising from or
            relating to the website, exceed the total amount paid to the Company
            by a User for the Services under which such liability arose.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The Website may contain links to third-party websites that are not
            owned or controlled by the Company. The Company does not have any
            control over and assumes no responsibility for, the content, privacy
            policies, terms of use, or practices of any third-party websites. In
            addition, the Company will not and cannot censor or edit the content
            of any third-party site. By using third-party websites, you
            expressly relieve the company from any liability arising from your
            use of any third-party website. Accordingly, please be advised to
            read the terms and conditions and privacy policy of each third-party
            website that you visit, including those directed by the links
            contained on the website.
          </div>
        ),
      },
    ],
  },
  {
    title: 'INDEMNIFICATION AND RELEASE',
    points: [
      {
        txt: (
          <div>
            You agree to defend, indemnify and hold harmless the Company, its
            affiliates, and its respective officers, managers, members,
            directors, employees, successors, assigns, subsidiaries, affiliates,
            suppliers, and agents, from and against any claims, damages,
            obligations, losses, liabilities, costs or debt, and expenses
            (including but not limited to attorneys' fees) arising from your use
            of, access to, and participation on the Website; your violation of
            any provision of the Terms and Conditions, including the privacy
            policy; your violation of any third-party right, including without
            limitation any copyright, property, proprietary, Intellectual
            Property Rights, or breach of your express or implied
            representations and warranties. This defence and indemnification
            obligation will survive these terms and conditions and your use of
            the Website.
          </div>
        ),
      },
      {
        txt: (
          <div>
            The Company is an intermediary which acts as a mere facilitator
            between the buyers and the sellers, hence, shall not be held
            accountable for any reasons whatsoever.{' '}
          </div>
        ),
      },
    ],
  },
  {
    title: 'BREACH OF TERMS AND CONDITIONS',
    points: [
      {
        txt: (
          <div>
            Without prejudice to the Company’s other rights under the Terms and
            Conditions, if a User breaches any provisions of these Terms and
            Conditions in any way, or if the Company reasonably suspects that a
            User has breached these Terms and Conditions in any way, the Company
            may take such action as it deems appropriate to deal with the
            breach, including (a) temporarily suspend such User’s access to the
            Website, (b) permanently prohibit such User from accessing the
            Website, (c) blocking computers/devices using such User’s IP address
            from accessing the Website, (d) contacting any or all of such User’s
            internet service providers and request that they block such User’s
            access to the Website, (e) commence legal action against such User,
            whether for breach of contract or otherwise; and/or (f) suspend or
            delete such User’s User Account.
          </div>
        ),
      },
    ],
  },
  {
    title: 'MODIFICATIONS TO OR TERMINATION OF THE WEBSITE',
    points: [
      {
        txt: (
          <div>
            <b>Modification or Cessation of Website</b>
            <div>
              The Company reserves the right to, at any time and from time to
              time, modify or discontinue, temporarily or permanently, the
              Website (or any part thereof) with or without notice and in its
              sole discretion. Users agree that the Company shall not be liable
              to the User or any third party for any modification, suspension,
              or discontinuance of the services offered by the Company.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Termination by The Company</b>
            <div>
              User hereby acknowledges and agree that the Company, in its sole
              and absolute discretion, has the right (but not the obligation) to
              delete, terminate, or deactivate User Account, block User’s email
              or IP address, cancel the Website or otherwise terminate User’s
              access to or participation in the use of the Website (or any part
              thereof), or remove and discard any content submitted by User on
              the Website, immediately and without notice, for any reason,
              including without limitation, User Account inactivity or if the
              Company believes or has reason to believe that the User has
              violated any provision of the Terms and Conditions.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Effect of Termination</b>
            <div>
              Upon termination of the User Account, User’s right to participate
              in the Website, including, but not limited to, User’s right to
              receive any rental shall automatically terminate. User
              acknowledges and agrees that right to receive any rental accrued
              for the period before termination is conditional upon User having
              (i) properly used the Website, (ii) adhered to the Terms and
              Conditions, (iii) maintained continuous activation of User
              Account, and (iv) participated on the Website. In the event of
              termination, the User Account will be disabled and the User may
              not be granted access to the User Account or any files or other
              data contained in the User Account. Notwithstanding the foregoing,
              residual data may remain in the Company’s system.
              <p className="mt-2">Upon termination
                of Service, the User’s access to the Website shall be immediately
                revoked. The provisions of these Terms and Conditions which by
                their very nature are intended to survive termination shall
                survive expiration or termination of the Website or User Account.</p>
            </div>
          </div>
        ),
      },
    ],
  },
  {
    title: 'REFUND POLICY',
    points: [
      {
        txt: (
          <div>
            <div>
              Refund queries will be catered in case of frauds and disputes
              only. A user has to inform about any such activity by dropping an
              email to <a href='mailto:service@gojust.life'>service@gojust.life</a>
              within 48 hours of learning/ascertaining about the same. The
              Dedicated team will then contact the user within 48 hours to
              understand and analyse the incident. The refunds will be issued
              after complete verification and due diligence by our team. In case
              the concern is not addressed within 48 hours, the user can reach
              out to the Grievances Officer on
              <a href='mailto:grievanceofficer@bijak.in'>
                grievanceofficer@bijak.in.
              </a>{' '}
              The refund will be transferred into the same account from which
              the money was debited subject to successful completion of the
              verification and due diligence process by our dedicated team.
            </div>
          </div>
        ),
      },
    ],
  },
  {
    title: 'MISCELLANEOUS',
    points: [
      {
        txt: (
          <div>
            <b>Charges for Services</b>
            <div>
              The Company may charge a commission/fees for its Services, on a
              case to case basis.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Governing Law and Jurisdiction</b>
            <div>
              The Terms and Conditions shall be governed in all respects by the
              laws of India and any legal proceeding arising out of this
              Agreement will occur exclusively in the courts located in
              [Haryana], India.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Advertisements</b>
            <div>
              The Company may display advertisements and promotions on the
              Website. The manner, mode, and extent of advertising by the
              Company on the Website are subject to change and the appearance of
              advertisements on the Website does not imply endorsement by the
              Company of any advertised products or services. The User agrees
              that the Company shall not be responsible or liable for any loss
              or damage of any sort incurred by the User as a result of any such
              dealings or as the result of the presence of such advertisers on
              the Website.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Use of Cookies</b>
            <div>
              <ol>
                <li>
                  The Company uses cookies to aggregate information about the
                  User’s sessions, track information, store certain User
                  preferences, analyze web page flow, etc. so that the Company
                  can update and redesign the Company Website, as necessary, to
                  provide User with the most useful information.
                </li>
                <li>
                  Users will have the option to either accept or decline the use
                  of cookies on their computer/device, whether they are
                  registered or not. However, a User’s experience of the Website
                  shall be limited and certain features of the Website will not
                  be available if the User declines the use of Cookies.
                </li>
              </ol>
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Assignment</b>
            <div>
              The Terms and Conditions, and any rights and licenses granted
              hereunder, may not be transferred or assigned by the User but may
              be assigned by the Company without restriction.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>No Agency or Partnership</b>
            <div>
              No agency, partnership, joint venture, or employment is created as
              a result of the Terms and Conditions or User’s use of any part of
              the Website, including without limitation, the contract between
              the User and The Company. The user does not have any authority
              whatsoever to bind the Company in any respect. Neither the Company
              nor any User of the Website may direct or control the day-to-day
              activities of the other or create or assume any obligation on
              behalf of the other.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Force Majeure</b>
            <div>
              Neither the Company nor the User shall be liable to the other for
              any delay or failure in performance under the Terms and
              Conditions, other than payment obligations, arising out of a cause
              beyond its control and without its fault or negligence. Such
              causes may include but are not limited to fires, floods,
              earthquakes, strikes, unavailability of necessary utilities,
              blackouts, acts of God, acts of declared or undeclared war, acts
              of regulatory agencies, or national disasters.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>No Third-party Beneficiaries</b>
            <div>
              User agrees that, except as otherwise expressly provided in the
              Terms and Conditions, there shall be no third-party beneficiaries
              to the Terms and Conditions.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Notice</b>
            <div>
              User agrees that The Company may provide the User with notices,
              including those regarding changes to the Terms and Conditions, by
              email, regular mail, or postings on the Website.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Entire Agreement</b>
            <div>
              The Terms and Conditions, together with the Privacy Policy, and
              any other legal notices or any additional policies published by
              the Company on the Website, shall constitute the entire agreement
              between User and the Company concerning the Website. Please note,
              however, that other aspects of your use of Services may be
              governed by additional agreements.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Severability</b>
            <div>
              If any provision of the Terms and Conditions is deemed invalid by
              a court of competent jurisdiction, the invalidity of such
              provision shall not affect the validity of the remaining
              provisions of the Terms and Conditions, which shall remain in full
              force and effect.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>No Waiver</b>
            <div>
              No waiver of any provision of these Terms and Conditions shall be
              deemed a further or continuing waiver of such term or any other
              term, and The Company’s failure to assert any right or provision
              under these Terms and Conditions shall not constitute a waiver of
              such right or provision.
            </div>
          </div>
        ),
      },
      {
        txt: (
          <div>
            <b>Compliant & Grievance Mechanism</b> <br />
            To Address the queries/concerns of the users we have defined
            Complaint & Grievance Mechanism.
            <ol type='i'>
              <li>
                <div>
                  <b>Level 1:</b> Users can reach us through the “Help via Call”
                  feature on mob application or can write to us at&nbsp;
                  <a href='mailto:service@gojust.life'>service@gojust.life.</a>
                </div>

              </li>
              <li>
                <div>
                  <b> Level 2:</b> If the user query/concerns are not addressed
                  to the expected levels, then they can reach the Grievance
                  Officer- Mr. Jitender Bedwal. Email id:{' '}
                  <a href='mailto:grievanceofficer@bijak.in'>
                    {' '}
                    grievanceofficer@bijak.in;
                  </a>
                  &nbsp; Grievance officer acknowledges the receipt of any user
                  query/concerns within 48 hours and redresses the complaint
                  within 1 month from the date of receipt of the complaint.
                </div>
              </li>
              <li>
                <div>
                  {' '}
                  <b>Level 3:</b> In the unlikely event that your issue remains
                  unresolved to your satisfaction despite escalating to our
                  Grievance Officer, you can reach out to our Nodal Officer Mr.
                  Nukul Upadhye –&nbsp;
                  <a href='mailto:grievanceofficer@bijak.in'>
                    grievanceofficer@bijak.in
                  </a>
                  . We will respond within 3 business days from the date of
                  receipt of your email.
                </div>
              </li>
            </ol>
          </div>
        ),
      },
    ],
  },
];


