import { ProductsList, ProductsHeading, ProductsSubheading } from "./constant";
import Slider from "react-slick";

const Products = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    variableWidth:true,
  };
  return (
    <div className="products" data-scroll-index="2">
      <div className="container">
      <h2 className="pheading">{ProductsHeading}</h2>
      <div className="psubheading">{ProductsSubheading}</div>
      <div className="row">
        {ProductsList.map((item, index) => (
          <div className="col-md-6 col-sm-12 col-lg-4 pImg" key={index}>
            <img src={item.img} alt={item.altTag} title={item.altTag} className="pImg" />
            <div className="overlay">
              <div className="ptext">{item.txt}</div>
            </div>
          </div>
        ))}
      </div>
      </div>
        <Slider {...settings}>
          {ProductsList.map((item, index) => {
            return (
              <div className="pSlider" key={index}>
                <div className="content">
                <img src={item.img} alt={item.altTag} title={item.altTag} />
                <div className="pSliderTxt">{item.txt}</div>
                </div>
              </div>
            );
          })}
        </Slider>
    </div>
  );
};
export default Products;
