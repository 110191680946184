import React from "react";
import GooglePlayStoreIcon from "../../Common/GooglePlayStoreIcon";
import "./style.scss";
import FbImage from "../../../assets/retailer/retailer_fb.png";
import InstaImage from "../../../assets/retailer/retailer_insta.png";
import useMoengage from "../../../useMoengage";
import useFacebookPixel from "../../../useFacebookPixel";

const Footer = () => {
  const {trackEvent} = useMoengage();
  const {trackFBEvents} = useFacebookPixel();

  const handleEvent = () => {
    trackEvent({event: "social_icon_click_B2B", eventPayload:{page: 'retailer'}});
    trackFBEvents({isCustomTrack: true, event: "social_icon_click_B2B", eventPayload: {page: 'retailer'}});
  }

  return (
    <div className="justR_footer">
      <div className="container">
        <div className="left_section">
          <p className="download_heading">
            Download the <br /> JUST Retailer App
          </p>
          <GooglePlayStoreIcon
            isFooter={true}
            appLink="https://play.google.com/store/apps/details?id=com.just.fresh"
            from="just_retailer_footer"
            platform="android"
            event="get_on_gplay_click_B2B"
            pageName='retailer'
          />
        </div>
        <div className="right_section">
          <p className="just_follow">Follow <br className="for_mob"/> JUST Retailer on:</p>
          <div>
            <a href="https://www.facebook.com/JustFreshProduce" target="_blank" rel="noreferrer" onClick={handleEvent}>
              <img src={FbImage} alt="just retailer facebook" className="social_img" />
            </a>
            <a href="https://www.instagram.com/just_retailer/" target="_blank" rel="noreferrer" onClick={handleEvent}>
              <img src={InstaImage} alt="just retailer instagram" className="social_img" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
