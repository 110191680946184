import JustLogo from '../../assets/images/footer/just_footer_logo.png';
import Facebook from '../../assets/retailer/retailer_fb.png';
import Instagram from '../../assets/retailer/retailer_insta.png';
import CallIcon from "../../assets/images/footer/call.png";
import EmailIcon from "../../assets/images/footer/email.png";
import LocationIcon from "../../assets/images/footer/location.png";
import BijakLogo from '../../assets/images/footer/Bijak Main-English.png'

export const JUSTLogo = JustLogo;
export const FollowTxt = 'Follow us on :';
export const PoweredByTxt = 'Powered by';
export const BIJAKLogo = BijakLogo;
export const FacebookLogo = Facebook;
export const Copyright = `© ${new Date().getFullYear()} Krishiacharya Technologies Private Limited | All rights reserved`;
export const FacebookLink = 'https://www.facebook.com/justfreshproduceapp';
export const InstaLink = 'https://www.instagram.com/justfreshapp';
export const InstaLogo = Instagram;
export const callIcon = CallIcon;
export const emailIcon = EmailIcon;
export const locationIcon = LocationIcon;
export const callInfo = "Customer Care Number: 022-35500402 from Monday to Sunday, between 6:30AM to 10:30 PM";
export const emailInfo = "service@gojust.life";
export const locationInfo = "Plot 54, Delta Tower, 6th Floor, Sector 44, Gurugram, Haryana 122003";
