import { useEffect, useState } from "react";
const useNavbar = () => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [scroll, setScroll] = useState<boolean>(false);
  const handleScroll = () => {
    setScroll(window.scrollY > 50);
  };

  useEffect(() => {
    let isActive = true;
    if (isActive) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      isActive = false;
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return { toggle, setToggle, scroll };
};
export default useNavbar;
