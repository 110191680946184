import "./styles.scss";
import { Text, BtnTxt, MobileImg } from "./constants";
import useSendMessage from "./useSendMessage";

const SendMessage = () => {
  const { msg, mobile, Send, setMobile,loading } = useSendMessage();
  return (
    <div className="message-container">
      <div className="container d-flex align-items-center h-100 justify-content-between">
        <div className="message-text">{Text}</div>
        <div className="position-relative flex-1">
          <input
            className="message-input"
            type="text"
            placeholder="Enter your mobile number"
            value={mobile}
            onChange={({ target }) => setMobile(target.value)}
          />
          <div className="error-message">{msg}</div>
        </div>
        <button className="message-button" onClick={() => Send()}>
          {BtnTxt}
          {loading ? (
            <i className="fa fa-circle-o-notch fa-spin"></i>
          ) : (
            MobileImg
          )}
        </button>
      </div>
    </div>
  );
};

export default SendMessage;
