import { Routes, Route, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.scss";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import TermsAndConditionsB2b from "./components/TermsAndConditions/termsB2b";
import TermsAndConditionsB2c from "./components/TermsAndConditions/termsB2c";
import PrivacyPolicy from "./components/PrivacyPolicy";
import NotFound from "./components/NotFound";
import JustRetailer from "./components/justRetailer";
import { useEffect } from "react";
import useMoengage from "./useMoengage";
import useFacebookPixel from "./useFacebookPixel";
import RiderTerms from "./components/riderTerms&Privacy/RiderTerms";
import RiderPrivacy from "./components/riderTerms&Privacy/RiderPrivacy";
import WarehouseTerms from "./components/warehouseTerms&Privacy/WarehouseTerms";
import WarehousePrivacy from "./components/warehouseTerms&Privacy/WarehousePrivacy";

function App() {
  const {trackEvent} = useMoengage();
  const {trackFBEvents} = useFacebookPixel();
  
  useEffect(() => {
    setTimeout(() => {
      trackEvent({event: "website_page_load", eventPayload: {page: "homepage", event_type: "pageload"}});
      trackFBEvents({event: "website_page_load", isCustomTrack: true, eventPayload: {type: "pageload"}});
    }, 3000)
  }, [])

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/"  element={<Home/>}  />
        <Route path="/termsb2b" element={<TermsAndConditionsB2b/>} />
        <Route path="/terms" element={<TermsAndConditionsB2c/>} />
        <Route path="/privacy" element={<PrivacyPolicy/>} />
        <Route path="/just-retailer" element={<JustRetailer/>} />
        <Route path="/rider-terms" element={<RiderTerms />} />
        <Route path="/rider-privacy" element={<RiderPrivacy />} />
        <Route path="/warehouse-terms" element={<WarehouseTerms />} />
        <Route path="/warehouse-privacy" element={<WarehousePrivacy />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate replace to='/404' />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
