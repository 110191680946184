import { useEffect } from "react";
import ReactGA from "react-ga4";

const useGoogleAnalytics = () => {
  useEffect(() => {
    if(process.env.REACT_APP_GA_KEY){
      ReactGA.initialize(process.env.REACT_APP_GA_KEY);
    }
  }, []);

  const clickEvent = (category: string, action: string, label?: string) => {
    ReactGA.event({
      category,
      action,
      label,
    });
  };

  return { clickEvent };
};

export default useGoogleAnalytics;
