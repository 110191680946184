import Benefits from "./Benefits";
import Features from "./Features";
import Hero from "./Hero";
import InfoBox from "../Footer/Info";
import SendMessage from "./SendMessage";
import Testimonials from "./Testimonials";
import { useEffect } from "react";
import { Helmet } from 'react-helmet';
import "./style.scss";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="overflow-hidden just_fresh_site">
      <Helmet>
        <title>JUST Fresh - Buy Fruits & Vegetables</title>
        <meta name="description" content="Buy farm fresh fruits & vegetables from Just Fresh. Flat 30% Cashback on first order, Code: CASHBACK30. Home delivery at best prices in Delhi NCR"/>
        <link rel="canonical" href="https://www.buyjustfresh.com/" />
      </Helmet>
      <Hero />
      <SendMessage />
      <Features />
      <Benefits />
      <Testimonials/>
      <InfoBox/>
    </div>
  );
};
export default Home;
