import React from "react";
import heroImage from "../../../assets/images/JustRetailer/hero/hero_image.webp";
import "../About/style.scss";
const About = () => {
  return (
    <div className="justR_about">
      <div className="container">
        <img src={heroImage} alt="heroImage" />
        <p>
          Just™ delivers fruits & vegetables that are fresh, delicious, and packed with love. Our team handpicks every product that further undergoes rigorous
          quality checks to ensure that consumers get only the freshest, nutritious, and, most importantly, safest produce. You deserve nothing less!
        </p>
      </div>
    </div>
  );
};

export default About;
