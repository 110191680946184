import FarmImage from "../../../assets/images/features/farm.webp";
import CucumberImage from "../../../assets/images/features/cucumber.webp";
import PackagingImage from "../../../assets/images/features/packaging.webp";
import DeliveryImage from "../../../assets/images/features/delivery.webp";
import {
  FeaturesDelivered,
  FeaturesPacked,
  FeaturesSorted,
  FeaturesSourced,
} from "../../../svg";
export const PicturesData = [
  {
    heading: "Straight From The Farm",
    subtitle:
      "At Just Fresh, we source fruits and vegetables directly from reputed farms to ensure freshness.",
    imageCol: "col-md-12 col-lg-6",
    contentCol: "col-md-12 col-lg-6 m-auto",
    img: FarmImage,
    showDownload: false,
    altTag: 'Ensure Farm Fresh Vegetables Delivery at your Doorstep'
  },
  {
    heading: "Rigorous Quality Checks",
    subtitle:
      "All our produce is sorted with precision, cleaned thoroughly and undergoes rigorous quality checks. We ensure that you only receive the freshest, most nutritious, and safest produce from us.",
    imageCol: "col-md-12 col-lg-6 m-auto order-lg-2 order-md-1",
    contentCol: "col-md-12 col-lg-6 order-lg-1 order-md-2 m-auto",
    img: CucumberImage,
    showDownload: false,
    altTag: 'Best Quality Fruits and Vegetables available in NCR for Online Delivery'
  },
  {
    heading: "Hygienic Packaging",
    subtitle:
      "All JUST Fresh fruits and vegetables are packed in moisture-controlled hygienic containers after cleaning and sorting.",
    imageCol: "col-md-12 col-lg-6",
    contentCol: "col-md-12 col-lg-6 m-auto",
    img: PackagingImage,
    showDownload: false,
    altTag: 'Just Ensures A-one Packaging for your Fruits & Vegetable Delivery'
  },
  {
    heading: "Same Day Delivery Available",
    subtitle:
      "We deliver at your convenience. Order before 10 AM and get same-day delivery of fresh fruits and vegetables.",
    imageCol: "col-md-12 col-lg-6 m-auto order-lg-2 order-md-1",
    contentCol: "col-md-12 col-lg-6 order-lg-1 order-md-2 m-auto",
    img: DeliveryImage,
    showDownload: true,
    altTag: 'Get Hassle Free On-time Delivery of your Fruits and Vegetable Order'
  },
];
export const Heading = "How JUST Fresh Produce Reaches You";
export const FeaturesData = [
  {
    title: "Sourced",
    content:
      "We source fresh fruits and vegetables directly from farms every single day.",
    img: <FeaturesSourced />,
  },
  {
    title: "Sorted",
    content: "Every JUST Fresh product undergoes rigorous quality checks.",
    img: <FeaturesSorted />,
  },
  {
    title: "Packed",
    content:
      "We clean our fruits & veggies and then pack them in moisture-controlled hygienic containers.",
    img: <FeaturesPacked />,
  },
  {
    title: "Delivered",
    content:
      "We get your order delivered to your home as per the delivery slot you choose.",
    img: <FeaturesDelivered />,
  },
];
