import "./styles.scss";
import Menu_Icon from "../../assets/images/common/menu.webp";
import Cross_Icon from "../../assets/images/common/cross.webp";
import { JustLogo } from "../../svg";
import cx from "classnames";
import GooglePlayStoreIcon from "../Common/GooglePlayStoreIcon";
import useNavbar from "./useNavbar";
import { Link } from "react-router-dom";
import useMoengage from "../../useMoengage";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";
import useFacebookPixel from "../../useFacebookPixel";

const Navbar = () => {
  const { toggle, setToggle, scroll } = useNavbar();
  const {trackEvent} = useMoengage();
  const {clickEvent} = useGoogleAnalytics();
  const {trackFBEvents} = useFacebookPixel();

  const onOrderNowBtnClick = () => {
    trackEvent({event: "order_now_click", eventPayload:{}});
    clickEvent('order_now_click', 'click');
    trackFBEvents({isCustomTrack: true, event: "order_now_click", eventPayload: {}})
    window.open('https://app.justfreshlife.com/', '_blank');
    setToggle(!toggle);
  }

  return (
    <nav className={cx("navbar navbar-expand-md", { "box-shadow": scroll })}>
      <div className="container">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/" className="nav-link logo p-0" onClick={() => window.scrollTo(0, 0)}>
              <JustLogo />
            </Link>
            <ul
              className={cx("align-items-center m-0 navbar-nav me-auto bg-white", {
                active: toggle,
              })}
            >
              <li className="nav-item cross_icon" onClick={() => setToggle(!toggle)}>
                <img src={Cross_Icon} alt="cross_icon" className="cross_icon" />
              </li>
              {/* <li onClick={onOrderNowBtnClick} className="navbar_order_now">
                <p>Order Now</p>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link" to="/" data-scroll-nav="1" onClick={() => setToggle(!toggle)}>
                  Features
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/" data-scroll-nav="2" onClick={() => setToggle(!toggle)}>
                  Benefits
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/" data-scroll-nav="3" onClick={() => setToggle(!toggle)}>
                  Testimonials
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://blog.buyjustfresh.com/" target="_blank" rel="noreferrer">
                  Blogs
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/" data-scroll-nav="4" onClick={() => setToggle(!toggle)}>
                  Contact us
                </Link>
              </li>
            </ul>
          </div>

          <div className="nav_app_link_container">
            <GooglePlayStoreIcon
              appLink="https://apps.apple.com/us/app/just-fresh-buy-fruit-vegetable/id1634024358"
              platform="ios"
              from="header_main_menu"
              event="get_on_iOS"
            />
            <GooglePlayStoreIcon appLink="https://gojust.store/Get_App" from="header_main_menu" platform="android" event="get_on_gplay_click" />
          </div>

          <img src={Menu_Icon} alt="menu_icon" className="menu_icon" onClick={() => setToggle(!toggle)} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
